import styled from "@emotion/styled";
import { useState } from "react";
import UploadIcon from '@mui/icons-material/Upload';
import IconImageLoaded from '@mui/icons-material/Image';

const IconStyled: React.FC<{ type: "upload" | "loaded"}> = ({ type }) => {
    const [isLoadedType,] = useState<boolean>(((type: "loaded" | "upload"): boolean => type === "loaded")(type));
    const Icon: React.FC = styled(isLoadedType ? IconImageLoaded : UploadIcon)`
        font-size: 30px;
        background: ${isLoadedType ? '#E0FAEB' : '#E9EFFB;'};
        border-radius: 5px;
        padding: 10px;
        color: ${isLoadedType ? '#2DC76D' : '#2F61D5'};
        margin: 0 auto;
    `;
    return <Icon />
};
export default IconStyled;