import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Grid,
  FormHelperText,
  TextField,
} from "@mui/material";
import { UserFormProps } from "../UserForm";
import ClientSelection from "../components/ClientSelection";
import Tooltip from "components/UI/Tooltip";
import ContainerButtonsForm from "../components/ContainerButtonsForm";
import InputErrorLabel from "components/UI/InputErrorLabel";
import InformationImagePicture from "../components/InformationImagePicture/InformationImagePicture";

type PickedUserFormProps = "register" | "errors"| "handleImageChange" | "userImage" | "handleClickOpenCamera" | "isValid" | "watch" | "setValue";
export type UserFormAddProps = Pick<UserFormProps, PickedUserFormProps>;

const UserFormAdd: React.FC<UserFormAddProps> = ({
    errors,
    setValue,
    watch,
    isValid,
	register,
	userImage,
	handleImageChange,
	handleClickOpenCamera,
}) => {
    const navigate = useNavigate();
    
	const handleOnClose = () => {
        navigate("/dashboard/users", { state: { titlePage: 'Gestión de Usuarios'}});
    };
   
	return (
		<Grid container spacing={4}>
            {/* Nombre */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    id="nombre"
                    label="Nombre"
                    variant="outlined"
                    type="text"
                    fullWidth
                    required
                    {...register("nombre", { required: true, minLength: 1 })}
                    error={!!errors.nombre}
                    helperText={errors?.nombre &&
                        <InputErrorLabel icon error>
                            El nombre es requerido.
                        </InputErrorLabel>}
                />
            </Grid>
            {/* Apellido */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("apellido", { required: true, minLength: 1 })}
                    id="apellido"
                    label="Apellido"
                    variant="outlined"
                    placeholder="Apellido"
                    type="text"
                    fullWidth
                    required
                    error={!!errors?.apellido}
                    helperText={errors?.apellido && <span>
                        <InputErrorLabel icon error>
                            El apellido es requerido.
                        </InputErrorLabel></span>}
                />
            </Grid>
            {/* CUIT */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("cuit")}
                    id="cuit"
                    label="CUIL"
                    variant="outlined"
                    placeholder="CUIL"
                    type="number"
                    fullWidth
                />
            </Grid>
            {/* User ID */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("userID", { required: true, minLength: 1 })}
                    id="userID"
                    label="UserID *"
                    variant="outlined"
                    placeholder="UserID"
                    type="text"
                    fullWidth
                    error={!!errors?.userID}
                    helperText={errors?.userID &&
                        <InputErrorLabel icon error>
                            El 'userId' es requerido.
                        </InputErrorLabel>}
                />
            </Grid>
            {/* DNI */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField 
                    {...register("dni")}
                    id="dni"
                    label="DNI"
                    variant="outlined"
                    placeholder="DNI"
                    type="number"
                    fullWidth
                />
            </Grid>

            <Grid item sm={12} md={12} lg={12} xs={12}>
                <Typography variant="h2">
                    Información del cliente/empresa
                </Typography>
            </Grid>

            {/* Numero de legajo */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("legajo")}
                    id="legajo"
                    label="Legajo"
                    variant="outlined"
                    placeholder="Legajo"
                    type="text"
                    fullWidth
                />
            </Grid>
            {/* Select cliente */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <ClientSelection register={register} errors={errors} disabled={false} watch={watch} setValue={setValue} />
                <span>
                    {errors?.cliente &&
                    <FormHelperText>
                        <InputErrorLabel icon error>
                            El 'cliente' es requerido
                        </InputErrorLabel>
                    </FormHelperText>}
                </span>
            </Grid>

            {/* Upload / Take picture */}
            <ContainerButtonsForm
                handleImageChange={handleImageChange}
                selectedFile={userImage}
                onClick={handleClickOpenCamera}
                disabled={false}
                register={register}
            />

            {/* Instrucciones de la imagen */}
            <Grid item sm={12} md={12} lg={12} xs={12}>
                <InformationImagePicture />
            </Grid>

            {/* Container buttons 'enviar' y 'cancelar' */}
            <Grid item sm={12} md={12} lg={12} xs={12} textAlign="right">
                <Tooltip title="Cancelar creacion de usuario">
                    <Button variant="outlined" color="secondary" onClick={handleOnClose}>
                        Cancelar
                    </Button>
                </Tooltip>
                <Tooltip
                    title="Agregar Usuario"
                    disableHoverListener={!isValid || !!userImage}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid || !userImage }
                    >
                        Agregar Usuario
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
	);
};

export default UserFormAdd;
