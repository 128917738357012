import styled from "@emotion/styled";
import { Box } from "@mui/material";

const BoxContainerImageDotted = styled(Box)`
	border: 2px dashed #dbdbdb;
	border-radius: 25px;
	width: 412px;
	display: flex;
	align-items: center;
	transition: all .6s ease;
	@media (max-width: 768px){
		margin: 0 auto;
		> .__margin-mobile-top {
			margin-top: .7rem
		}
	}
`;

export default BoxContainerImageDotted;