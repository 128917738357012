import * as AxiosClient from '../services/clientAxiosBase';
import { UserType } from 'hooks/useUsers';
import { UserFormInputs } from 'components/UserForm/UserForm';
import utils from 'utils';

const { services: { manageAPIResponse, manageApiErrorResponse } } = utils;
const USER_TYPE = "usuario"

/**
 * The function `getUsers` makes an asynchronous request to fetch user data and handles any errors that
 * may occur.
 * @returns The `getUsers` function is returning an object with the following structure:
 * ```javascript
 * {
 *   status: number,
 *   message: string,
 *   data: UserType[] | null
 * }
 * ```
 * The `status` and `message` properties are determined based on the API response status and type of
 * user (USER_TYPE), while the `data` property contains an array of user objects (UserType
 */
const getUsers = async () => {
	try {
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios.get(`/users`);
		const res = {
			...manageAPIResponse(response?.status, USER_TYPE, "Usuarios obtenidos correctamente.", "get"),
			data: response?.data?.data?.usuarios as UserType[] || null
		};
		return res;
	} catch (error: any) {
		console.error({ getUsers: error?.response });
		return {
			...manageApiErrorResponse(error?.response?.statusCode || 500, USER_TYPE, "get", error?.response?.data?.message),
			data: null
		};
	}
};
/**
 * The function `getUserById` asynchronously fetches user data by ID using Axios and handles any errors
 * that may occur.
 * @param  - The `getUserById` function is an asynchronous function that takes an object as a parameter
 * with a property `id` which can be either a string or a number.
 * @returns The `getUserById` function is returning an object with the following structure:
 * {
 *   status: number,
 *   message: string,
 *   data: object
 * }
 */
const getUserById = async ({ id }: { id: string | number }) => {
	try {
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios.get(`/users/${id}`);
		const res = {
			...manageAPIResponse(response?.status, USER_TYPE, "Usuario obtenido correctamente.", "get"),
			data: response?.data || null
		};
		return res;
	} catch (error: any) {
		console.error({ userById: error?.response });
		return {
			...manageApiErrorResponse(error?.response?.statusCode || 500, USER_TYPE, "get", error?.response?.data?.message),
			data: null
		};
	}
};
/**
 * The function `postUserService` sends a POST request to create a new user with user data and an
 * optional user image.
 * @param {UserFormInputs} data - The `data` parameter in the `postUserService` function represents an
 * object containing user information such as name, last name, client ID, CUIT, custom ID, DNI, and
 * employee ID.
 * @param {File | null} userImage - The `userImage` parameter in the `postUserService` function is of
 * type `File | null`. This means it can either be a `File` object representing a file selected by the
 * user or `null` if no file is selected.
 * @returns The `postUserService` function is returning the result of calling the `manageAPIResponse`
 * function with the status from the response or error as well as the string "usuario".
 */
const postUserService = async (data: UserFormInputs, userImage: File | null) => {

	try {
		const formData = new FormData();
		formData.append('nombre', data.nombre);
		formData.append('apellido', data.apellido);
		formData.append('clientId', data.cliente);
		formData.append('cuil', data.cuit);
		formData.append('customId', data.userID);
		formData.append('dni', data.dni);
		formData.append('legajo', data.legajo);
		formData.append('foto', userImage as Blob);

		const axios = AxiosClient?.getInstanceAxios(true, true);
		const response = await axios?.post(`/users`, formData);		
		return manageAPIResponse(response?.status, USER_TYPE, "Usuario creado correctamente.", "create");
	} catch (error: any) {
		console.error({ createUser: error });
		return manageApiErrorResponse(error?.reponse?.statusCode, USER_TYPE,"create", error?.response?.data?.message);
	}
};
/**
 * The function `postSearchUsers` is an asynchronous function that searches for users based on a given
 * payload and handles the API response accordingly.
 * @param {string} payload - The `payload` parameter in the `postSearchUsers` function is a string that
 * represents the search query used to search for users in the system. This payload will be sent to the
 * server to retrieve user data based on the search query.
 * @returns The `postSearchUsers` function returns an object with the following structure:
 * ```json
 * {
 *     status: number,
 *     type: string,
 *     data: any
 * }
 * ```
 * The `status` field represents the HTTP status code of the response, the `type` field represents the
 * type of user (USER_TYPE), and the `data` field contains the data returned from the API call.
 */
const postSearchUsers = async (payload: string) => {
    try {
        const axios = AxiosClient?.getInstanceAxios(true, false);
		const response = await axios?.get(`/users/search/${payload}`);
		const { data } = response;
		const res = {
			...manageAPIResponse(response?.status, USER_TYPE, "Usuarios obtenidos correctamente.", "get"),
			data: data?.data || null
		};
		return res;
    } catch (error: any) {
        console.error({ searchUser: error?.response });
		return {
			...manageApiErrorResponse(error?.response?.statusCode, USER_TYPE, "get", error?.response?.data?.message), 
			data: null
		};
    }
};
/**
 * The function `postUpdateUser` sends a PUT request to update user data along with an image file using
 * Axios in TypeScript.
 * @param {UserType} data - The `data` parameter in the `postUpdateUser` function is of type
 * `UserType`, which likely contains information about a user such as their name, client ID, custom ID,
 * CUIL, DNI, and legajo. It seems to be used to update user information in the backend
 * @param {File | null} userImage - The `userImage` parameter in the `postUpdateUser` function is of
 * type `File | null`. This means it can either be a `File` object representing a file selected by the
 * user, or `null` if no file is selected. The function uses this parameter to update the user
 * @returns The `postUpdateUser` function is returning the result of calling the `manageAPIResponse`
 * function with the `status` property of the `response` object and the `USER_TYPE` constant as
 * arguments. If an error occurs, it will return the result of calling `manageAPIResponse` with the
 * `statusCode` property of the `error.response` object and the `USER_TYPE` constant as
 */
const postUpdateUser = async (data: UserType, userImage: File | null) => {
	const getCuit = (data: any) => data?.cuit < 0 ? "" : data.cuit;
	const getDni = (data: any) => data?.dni < 0 ? "" : data.dni;

	try {
		const formData = new FormData();
		formData.append('nombre', data.nombre);
		formData.append('apellido', data.apellido);
		formData.append('clientId', data.cliente);
		formData.append('customId', `${data.userID}`);
		formData.append('cuil', getCuit(data));
		formData.append('dni', getDni(data));
		formData.append('legajo', data.legajo);

		if (!!userImage || !!data.imagen) {
			formData.append('foto', (userImage || data.imagen) as Blob);
		}

		const axios = AxiosClient.getInstanceAxios(true, true);
		const response = await axios.put(`/users/${data.userId}`, formData);
		return manageAPIResponse(response?.status, USER_TYPE, "Usuario modificado correctamente.", "update");
	} catch (error: any) {
		console.error({ updateUser: error?.response });
		return manageApiErrorResponse(error?.response?.statusCode, USER_TYPE, "update", error?.response?.data?.message);
	}
};
/**
 * The function `deleteUser` is an asynchronous function that sends a DELETE request to delete a user
 * by their ID and handles the API response accordingly.
 * @param  - The `deleteUser` function is an asynchronous function that takes an object as a parameter
 * with a property `id` of type `number` or `string`. The function then uses Axios to send a DELETE
 * request to the API endpoint `/users/` to delete a user with the specified `id
 * @returns The `deleteUser` function is returning the result of calling the `manageAPIResponse`
 * function with the `response?.status` or `error?.response?.statusCode` and the `USER_TYPE` as
 * arguments.
 */
const deleteUser = async ({ id }: { id: number | string }) => {
	try {
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios.delete(`/users/${id}`);
		return manageAPIResponse(response?.status, USER_TYPE, response?.data?.data || "Usuario eliminado correctamente.", "delete");
	} catch (error: any) {
		console.error({ deleteUser: error?.response });
		return manageApiErrorResponse(error?.response?.statusCode, USER_TYPE, "delete", error?.response?.data?.message);
	};
};

const service = {
	getUsers,
	getUserById,
	deleteUser,
	postUpdateUser,
    postUserService,
	postSearchUsers,
};

export default service;
