import React, { useState, useEffect, useMemo } from 'react';
import { Select, MenuItem } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';
import { UserFormInputs } from 'components/UserForm/UserForm';
import { getClients } from 'services/clientsServices';
import { Client } from 'hooks/useClientForm';
interface ClientSelectionProps {
  register: UseFormRegister<UserFormInputs>;
  errors: any;
  disabled: boolean;
  setValue: any;
  watch: any;
  value?: any;
}

const ClientSelection: React.FC<ClientSelectionProps> = ({ register, errors, disabled, setValue, watch, value }) => {
  const [clients, setClients] = useState<any[]>([]);
  const [isClientsLoaded, setIsClientsLoaded] = useState<boolean>(false);

  useMemo(async () => {
      const response = await getClients();
      const clientsData = response.data;
      setClients(clientsData || []);
      setIsClientsLoaded(true);

      const defaultValue = value || 'seleccionar';
      if (clientsData?.some((c: Client) => c?.id === defaultValue?.id)) {
        setValue('cliente', defaultValue.id);
      }
  }, []);

  const selectedClient = watch('cliente');

  useEffect(() => {
    if (value?.id) {
      setClients([value]);
      setIsClientsLoaded(true);
      setValue('cliente', value?.id);
    }
  }, [value]);

  const handleOnChange = (ev: any) => {
    setValue('cliente', ev?.target.value)
  }

  return (
    <Select
      labelId="cliente-label"
      fullWidth
      required
      value={isClientsLoaded ? selectedClient || "seleccionar": ''}
      {...register('cliente', { required: true, validate: value => value !== "seleccionar" })}
      error={!!errors?.cliente}
      disabled={disabled}
      onChange={handleOnChange}
    >
      <MenuItem value="seleccionar">Seleccionar cliente</MenuItem>
      {clients.map(client => (
        <MenuItem key={client.nombre} value={client.id}>{client.nombre}</MenuItem>
      ))}
    </Select>
  );
}
export default ClientSelection;
