import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, TablePagination } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Tooltip from 'components/UI/Tooltip';
import utils from '../../../utils/api';
import { UserType } from 'hooks/useUsers';
import './UsersTable.css'; 
import { styled } from '@mui/system';

interface UsersTableProps {
    rows: UserType[];
    handleDetailUser: (row: UserType) => void;
    handleEditUser: (row: UserType) => void;
    handleDeleteUser: (row: UserType) => void;
};
export type GridRowProps = {
    row: UserType;
    handleOpenModal: (userId: string, imagen: string) => void;
    index: number;
    setPage: (value: any) => void;
    handleDetailUser: (row: UserType) => void;
    handleDeleteUser: (row: UserType) => void;
    handleEditUser: (row: UserType) => void;
};
const rowsPerPageOptions = [5, 10, 15, 25];
const ButtonOpenModal = styled('button')`
    background: none;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
`;
const GridRowHeadTable = styled(Grid)`
    display: flex;
    width: 100%;
    align-items: center;
`;
const GridRowTable: React.FC<GridRowProps> = ({
    row,
    index,
    setPage,
    handleEditUser,
    handleOpenModal,
    handleDetailUser,
    handleDeleteUser,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Grid item xs={3} className="table-cell" textAlign="left">
                <p>{`${row.nombre} ${row.apellido}`}</p>
            </Grid>
            <Grid item xs={3} className="table-cell" textAlign="left">
                <p>{row.customId}</p>
            </Grid>

            {/* <Grid item xs={2} className="table-cell" textAlign="left">
                <p> {row.cuil} </p>
            </Grid> */}

            <Grid item xs={2} className="table-cell" textAlign="left">
                <p>{row?.sistema?.nombre || "-"}</p>
            </Grid>
            <Grid item xs={2} className='hand-cursor table-cell' display="flex" alignItems="left">
                <ButtonOpenModal onClick={() => handleOpenModal(row.customId, row.foto)} style={{ margin: '0 auto auto 0' }}>
                    <Tooltip title="Abrir imagen">
                        <p>Ver Imagen </p>
                    </Tooltip>
                </ButtonOpenModal>
            </Grid>

            {/* icon dots */}
            <Grid item xs={2}
                className="table-cell"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconButton
                    aria-controls={`actions-menu-${index}`}
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    sx={{
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id={`actions-menu-${index}`}
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={() => (handleDetailUser(row))}>Ver Detalle</MenuItem>
                    <MenuItem onClick={() => (handleEditUser(row))}>Editar Usuario</MenuItem>
                    <MenuItem onClick={() => {
                        handleDeleteUser(row);
                        setPage(0);
                    }}>Eliminar Usuario</MenuItem>
                </Menu>
            </Grid>
        </React.Fragment>
    );
};

const UsersTable: React.FC<UsersTableProps> = ({ rows, handleDeleteUser, handleDetailUser, handleEditUser }) => {

    const [openModal, setOpenModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [modalUserID, setModalUserID] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const { getUid } = utils;

    useEffect(
        () => { 
            setPage(0)
        }, [rows.length]
    );

    const handleOpenModal = (id: string, imageSrc: string) => {
        setOpenModal(true);
        setModalImage(imageSrc);
        setModalUserID(id);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalUserID('');
        setModalImage('');
    };

    const handleChangePage = (event: unknown, newPage: number) => { setPage(newPage); };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container display="flex" justifyContent="center" sx={{ width: '95%', margin: "0 auto" }}>
            <GridRowHeadTable>
                <Grid item xs={3} className="table-cell" textAlign="left">
                    <p className="table-title">Nombre y Apellido</p>
                </Grid>
                <Grid item xs={3} className="table-cell" textAlign="left">
                    <p className="table-title">UserID</p>
                </Grid>
                {/* <Grid item xs={2} className="table-cell" textAlign="left">
                    <p className="table-title">CUIL</p>
                </Grid> */}
                <Grid item xs={2} className="table-cell" textAlign="left">
                    <p className="table-title">Sistema</p>
                </Grid>
                <Grid item xs={2} className="table-cell" textAlign="left">
                    <p className="table-title">Imagen</p>
                </Grid>
                <Grid item xs={2} className="table-cell" style={{ textAlign: 'right' }}>
                    <p className="table-title">Acciones</p>
                </Grid>
            </GridRowHeadTable>

            <Grid container>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <GridRowTable
                        row={row}
                        key={getUid() + index}
                        handleDeleteUser={handleDeleteUser}
                        handleDetailUser={handleDetailUser}
                        handleEditUser={handleEditUser}
                        handleOpenModal={handleOpenModal}
                        index={index}
                        setPage={setPage}
                    />))}
            </Grid>
            {/* IMAGE MODAL */}
            <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>
                    {modalUserID}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <figure>
                        <picture>
                            <img src={modalImage} alt="Imagen en modal" style={{ width: '100%', height: 'auto' }} />
                        </picture>
                    </figure>
                </DialogContent>
            </Dialog>
          
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows?.length || 5}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={
                    <span className="custom-label">Filas por página</span>
                }
                sx={{
                    marginLeft: 'auto', 
                    color:'#6E6E6E',
                    fonWeight:'700',
                }}
            />


        </Grid>
    );
}

export default UsersTable;
