import React, { useEffect } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './CameraPermissions.css';

interface CameraPermissionsProps {
  onPermissionGranted: () => void;
}

const CameraPermissions: React.FC<CameraPermissionsProps> = ({ onPermissionGranted }) => {
  
  const checkCameraPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        onPermissionGranted();
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  useEffect(() => {
    checkCameraPermissions();
  }, [onPermissionGranted]);

  const handleAlertClose = () => {
    checkCameraPermissions(); 
  };

  return (
    <div className="custom-alert">
      <div className="custom-alert-content">
        <div className="custom-alert-image">
          <img src="/assets/frontapp01.png" alt="Front App" />
        </div>
        <div className="custom-alert-text">
          <b>Debes brindar permisos.</b> <br />
          Para poder escanear tu rostro debes permitirnos el acceso a tu cámara.
        </div>
        <div className="close-btn">
          <IconButton onClick={handleAlertClose} size="small">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default CameraPermissions;
