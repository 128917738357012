import Drawer from './components/Drawer';

export type MenuProps = {
	open: boolean;
	setOpen: (v: boolean) => void;
}

const Menu = ({
	setOpen,
	open,
}: MenuProps) => {
	const handleDrawerClose = () => (setOpen(!open));
	
	return (
		<Drawer
			open={open}
			setOpen={setOpen}
			handleDrawerClose={handleDrawerClose}
		/>
	);
};
export default Menu;