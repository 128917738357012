import { useState } from "react";

export type AlertSeverityType = "error" | "warning" | "success";

const useDisplayAlert = () => {

	const [alertData, setAlertData] = useState<{
		alertType: AlertSeverityType;
		alertMessage: string | undefined;
    } | null>(null);
    
    return {
        alertData,
        setAlertData
    }
}

export default useDisplayAlert;