import { Box, Button, Dialog, DialogTitle, Divider, Grid, styled, Typography } from "@mui/material";
import ClientsTable from "components/Clients/ClientsTable";
import useClientForm, { Client } from 'hooks/useClientForm';
import { useNavigate } from "react-router-dom";
import { useContext, useMemo, useState } from "react";
import ButtonAddClient from '../../components/UI/ButtonNavLink+';
import { AlertContext, AlertContextProps } from "context/AlertContext/AlertProvidert";
import Tooltip from "components/UI/Tooltip";
import TextField from "components/UI/TextField";

const ImgStyled = styled('img')`
  width: 240px;
  margin-right: 10px;
`;
const BoxStyled = styled(Box)`
    width: 400px;
    height: auto;
    padding: 0 1rem 0.5rem 0;
    overflow: hidden;
`;
const ClientsScreen = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [input, setInput] = useState<string | null>(null);
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const { clients, client, setClient, services: { handleDeleteClient: deleteClient, handleGetClients } } = useClientForm();
    const navigate = useNavigate();

    useMemo(
        async () => {
            const { data, alertMessage, alertType } = await handleGetClients();
            if (!data || (alertType === "error" || alertType === "warning")) {
                setAlertData({ alertMessage, alertType })
            }
        }, []
    );
    
    const handleDetailClient = (row: Client) => {
        navigate(`/dashboard/clients-manager/${row?.id}`, { state: { titlePage: 'Gestión de Sistemas' } });
    };
    const handleDeleteClient = async (row: Client) => {
        const response = await deleteClient(row?.id);
        setAlertData(response);
    };
    const handleCloseModalConfirm = async (confirm: boolean = false) => {
        if (confirm && client) {
            await handleDeleteClient(client);
        }
        setOpenModal(false);
        setInput(null)
    }
    const handleOpenModalConfirm = (row: Client) => {
        setOpenModal(true);
        setClient(row);
    }
    const handleUpdateClient = async (row: Client) => {
        navigate(`/dashboard/clients-manager/${row?.id}/edit`, { state: { titlePage: 'Gestión de Sistemas' } });
    };

    const isValid = input?.toUpperCase() === client?.nombre?.toUpperCase();
    
    return (
        <>
            <Dialog open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "hidden"}}
            >
                <DialogTitle>
                    Eliminar sistema
                </DialogTitle>
                <Divider sx={{ margin: "0"}} />
                <BoxStyled>
                    <Typography variant="h6" textAlign="center" marginTop={2} marginBottom={2}>
                        <strong>Desea eliminar el sistema ? </strong>
                    </Typography>
                    
                    <Grid container>
                        <Grid item sm={12} md={10} lg={10} xs={12} margin="0 auto">
                            <TextField
                                id="eliminar"
                                label="Introduzca el nombre del sistema"
                                variant="outlined"
                                type="text"
                                fullWidth
                                required
                                value={input}
                                onChange={(ev) => {setInput(ev.target.value)}}
                                error={!isValid}
                            />
                        </Grid>
                    </Grid>

                    <Grid container display="flex">
                        <Grid item margin="0 auto">
                            <Tooltip title="Aceptar">
                                <Button
                                    variant="outlined"
                                    disabled={!isValid}
                                    color="inherit"
                                    onClick={() => handleCloseModalConfirm(true)}
                                >
                                    Eliminar
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item margin="0 auto">
                            <Tooltip title="Cancelar">
                                <Button variant='contained' color="error" onClick={() => handleCloseModalConfirm()}>
                                    Cancelar
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </BoxStyled>
            </Dialog>
            <Grid container width="100%" alignItems="center" marginBottom="2rem" marginTop="6rem">

                <Grid item margin="0 auto" width="100%">
                    <ButtonAddClient
                        title="Nuevo Sistema"
                        toUrl="/dashboard/clients-manager/create"
                        state={{ titlePage: 'Alta de Sistemas' }}
                    > Nuevo Sistema</ButtonAddClient>
                </Grid>

                {(!clients || clients?.length === 0) &&
                    <Grid item margin="0 auto" width="100%">
                        <Box textAlign='center'>
                            <ImgStyled src="/assets/images/archivos.png" alt="No se encontraron registros" />
                            <Typography textAlign='center' color='#78909C' fontSize='17px' fontWeight='600'>No se encontraron registros</Typography>
                        </Box>
                    </Grid>}
                
                {Array.isArray(clients) && clients?.length > 0 &&
                    <ClientsTable
                        rows={clients}
                        handleDeleteClient={handleOpenModalConfirm}
                        handleDetailClient={handleDetailClient}
                        handleUpdateClient={handleUpdateClient}
                    />}
                </Grid>
        </>
    );
};

export default ClientsScreen;