import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { CheckCircleOutline, Warning, Error } from '@mui/icons-material';

interface CustomAlertProps {
  severity: 'success' | 'warning' | 'error';
  message: string | undefined;
  open: boolean;
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ severity, message, open, onClose }) => {
  // Definir el ícono correspondiente a cada tipo de alerta
  const getIcon = (severity: 'success' | 'warning' | 'error') => {
    switch (severity) {
      case 'success':
        return <CheckCircleOutline sx={{ color: '#ffffff' }} />;
      case 'warning':
        return <Warning sx={{ color: '#ffffff' }} />;
      case 'error':
        return <Error sx={{ color: '#ffffff' }} />;
      default:
        return null;
    }
  };

  // Estilos personalizados para cada tipo de alerta
  const alertStyles = {
    success: { backgroundColor: '#12805C', color: '#ffffff' },
    warning: { backgroundColor: '#FF6000', color: '#ffffff' },
    error: { backgroundColor: '#C9252D', color: '#ffffff' }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      sx={{ width: 557 }}
    >
      <Alert
        severity={severity}
        onClose={onClose}
        sx={{
          ...alertStyles[severity],
          width: '100%', 
        }}
        icon={getIcon(severity)} 
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;
