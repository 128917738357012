import React, { useEffect, useState } from 'react';
import { TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff  } from '@mui/icons-material';
import ButtonLoginForm from './ButtonLoginForm';
import AlertLoginForm from './AlertLoginForm';
import useLogin from './UtilLoginForm/useLoginForm';
import './LoginForm.css';
import { NavLink } from 'react-router-dom';
import utils from 'utils/api';
import styled from '@emotion/styled';
import InputErrorLabel from 'components/UI/InputErrorLabel/InputErrorLabel';
import Tooltip from 'components/UI/Tooltip';

const NavLinkStyled = styled(NavLink)`
	color: #124680;	
`;

const LoginForm: React.FC = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { handleLogin, error, setError } = useLogin();
	const [showPassword, setShowPassword] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);

	const {
		containsLowerCase,
		containsNumber,
		containsSpecialChar,
		containsUpperCase,
		isPasswordLengthValid
	} = utils;

	const handleEnableButton = () => {
		setIsDisabled(username.trim() === '' || password.trim() === '' || !isPasswordValid(password));
	};

	useEffect(() => {
		handleEnableButton();
	}, [password]);
 
	const isPasswordValid = (password: string) => {
		return (
			isPasswordLengthValid(password) &&
			containsNumber(password) &&
			containsUpperCase(password) &&
			containsLowerCase(password) &&
			containsSpecialChar(password)
		);
	};

	const ContainerLabelError = styled.span`
		margin-bottom: 5px;
		width: 100%;
		padding: 0;
	`;

	return (
		<Grid container spacing={4} alignItems="center" justifyContent="center">
      
			<Grid item xs={10}>
				<TextField
					label="Usuario"
					variant="outlined"
					fullWidth
					value={username}
					required
					onChange={(e) => {
						setUsername(e.target.value);
						handleEnableButton();
					}}
				/>
			</Grid>

			<Grid item xs={10}>
				<TextField
					label="Contraseña"
					variant="outlined"
					required
					fullWidth
					type={showPassword ? 'text' : 'password'}
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
						handleEnableButton();
					}}
					onPaste={(e) => {
						e.preventDefault();
						const pastedText = e.clipboardData.getData('Text');
						setPassword(pastedText);
						handleEnableButton();
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setShowPassword(!showPassword)}
									edge="end"
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					error={password.trim() !== '' && !isPasswordValid(password)}
					helperText={
						password.trim() !== '' && !isPasswordValid(password) && (
							<ContainerLabelError>
								{!isPasswordLengthValid(password) && <InputErrorLabel error icon>La contraseña debe tener al menos 8 caracteres.</InputErrorLabel>}
								{!containsNumber(password) && <InputErrorLabel error icon>Debe contener al menos 1 número.</InputErrorLabel>}
								{!containsUpperCase(password) && <InputErrorLabel error icon>Debe contener al menos una letra mayúscula.</InputErrorLabel>}
								{!containsLowerCase(password) && <InputErrorLabel error icon>Debe contener al menos una letra minúscula.</InputErrorLabel>}
								{!containsSpecialChar(password) && <InputErrorLabel error icon>Debe contener al menos un carácter especial.</InputErrorLabel>}
							</ContainerLabelError>
						)
					}
          
				/>
			</Grid>

			<Grid item style={{ textAlign: "right", textDecoration: "none" }} xs={10}>
				<Tooltip title="olvidé la contraseña">
					<NavLinkStyled to="/change-password" state={{ titlePage: 'Cambiar Contraseña'}}>¿Olvidaste la contraseña?</NavLinkStyled>
				</Tooltip>
			</Grid>

			<Grid item xs={10}>
				<Tooltip title="Iniciar Sesión">
					<ButtonLoginForm onClick={() => handleLogin(username, password)} isDisabled={isDisabled} />
				</Tooltip>
			</Grid>
      
			{error && <AlertLoginForm error={error} onClose={() => setError(null)} />}

		</Grid>
	);
};

export default LoginForm;
