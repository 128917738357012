import React from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <MuiButton variant="contained" color="primary" {...props}>
      {children}
    </MuiButton>
  );
}

export default Button;
