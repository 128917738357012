import { Grid, Typography } from "@mui/material";
import InputErrorLabel from "components/UI/InputErrorLabel";
import TextField from "components/UI/TextField";
import { IUseFormResponse } from "utils/hooks/useUtilsValidateForm";

const Step1: React.FC<IUseFormResponse> = ({ values, handleInputChange, errors, validateField }) => {
    return (
        <Grid container spacing={2} justifyContent='center'>
            <Typography variant='h6' width={'100%'} marginBottom={1}>
                <strong> Ingresá tu usuario para restablecer la contraseña </strong>
            </Typography>
            <Typography marginBottom={10} width={'100%'}>
                Te enviamos un correo con las instrucciones para restablecer la
                contraseña.
            </Typography>
            {/* field email */}
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    variant="outlined"
                    value={values?.email?.value || ''}
                    error= {!!errors?.email?.error}
                    onChange={(e) => handleInputChange('email', e.target.value)}
                    onKeyUp={() => validateField('email')}
                    helperText={
                        errors?.email?.error && <InputErrorLabel icon error>{ errors?.email?.error }</InputErrorLabel>
                    }
                    required
                />
                {  }
            </Grid>
            {/* field usuario */}
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    type="text"
                    name="usuario"
                    label="Usuario"
                    variant="outlined"
                    error={!!errors?.usuario?.error}
                    value={values?.usuario?.value || ''}
                    onChange={(e) => handleInputChange('usuario', e.target.value)}
                    onKeyUp={() => validateField('usuario')}
                    helperText={
                        errors?.usuario?.error && <InputErrorLabel icon error>{errors?.usuario?.error}</InputErrorLabel>
                    }
                    required
                />
                
            </Grid>
        </Grid>
    );
};

export default Step1;