import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import { PropsWithChildren } from "react";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "../Tooltip";

const WrapperStyled = styled('div')`
    display: flex;
    width: 100%;
    align-items: end;
    > .__btn-add-user {
        margin: 20px 2.5rem 1.5rem auto;
        > .tooltip > a.__new-user {
        text-decoration: none;
        color: white;
        }
        @media (max-width: 768px) {
        margin: 20px 1rem 1.5rem auto;
        }
    }
`;

const LinkStyled = styled(Link)`
    color: #ffffff;
`;

const ButtonNavLink = ({ children, onClick, title, toUrl, state }: PropsWithChildren<any>) => {
    return (
        <WrapperStyled>
          <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              startIcon={<AddIcon />}
              sx={{ borderRadius: '20px' }}
              className='__btn-add-user'
          >
              <Tooltip className='tooltip' title={title}>
                  <LinkStyled className='__new-user' to={toUrl} state={state}>
                      {children}
                  </LinkStyled>
              </Tooltip>
          </Button>
        </WrapperStyled>
      );
};

export default ButtonNavLink;