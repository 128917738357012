import React, { useState, useEffect } from 'react';
import { Grid, Alert } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning'; 

interface AlertLoginFormProps {
  error: { message: string; severity: 'error' | 'warning' } | null; 
  onClose: () => void;
}

/* TODO COMPONENT GLOBAL */

const AlertLoginForm: React.FC<AlertLoginFormProps> = ({ error, onClose }) => {
  const [visible, setVisible] = useState(true);


  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 10000); 

    return () => clearTimeout(timer);
  }, []); 

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        {error && (
          <Alert 
            variant="filled" 
            severity={error.severity}
            onClose={handleClose} 
            sx={{ 
              padding: '10px', 
              position: 'fixed', 
              bottom: '10px', 
              left: '50%',
              transform: 'translateX(-50%)',
              opacity: visible ? 1 : 0, 
              transition: 'opacity 0.5s ease', 
              zIndex: 9999 
            }}
            icon={<WarningIcon fontSize="inherit" />} 
          >
            {error.message}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
}

export default AlertLoginForm;
