import useDisplayAlert, { AlertSeverityType } from "hooks/useDisplayAlert";
import { createContext, ReactNode, useContext } from "react";

export type AlertContextProps = {
    setAlertData: (alertData?: any) => void;
    alertData: {
		alertType: AlertSeverityType;
		alertMessage: string | undefined;
    } | null
};

export const AlertContext = createContext<AlertContextProps | null>(null);

export const useAlert = () => {
    const context = useContext(AlertContext);
    if (context === null) throw new Error("useDisplayAlert must be used within the AlertProvider");
    return context;
}

export const AlertProvider: React.FC<{ children: ReactNode}> = ({ children }) => {
    const { ...props} = useDisplayAlert(); 
    return (
        <AlertContext.Provider value={{...props}} >
            { children }
        </AlertContext.Provider>
    );
};