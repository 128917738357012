import { Route, Routes } from 'react-router-dom';
import LoginScreen from './pages/Login';
import UserScreen from './pages/Users';
import LayoutLogin from 'layouts/Login/LayoutLogin';
import ForgotPasswordScreen from 'pages/ForgotPassword/ForgotPasswordScreen';
import DashboardLayout from 'layouts/Dashboard/DashboardLayout';
import AddUserScreen from 'pages/AddUser';
import ClientsScreen from 'pages/Clients/ClientsScreen';
import AddClientScreen from 'pages/AddClient/AddClientScreen';
import DetailClientScreen from 'pages/DetailClient/DetailClientScreen';
import EditClientScreen from 'pages/EditClient/EditClientScreen';
import EditUserScreen from 'pages/EditUser/EditUserScreen';
import DetailUserScreen from 'pages/DetailUser/DetailUserScreen';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutLogin />}>
        <Route index element={<LoginScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/change-password" element={<ForgotPasswordScreen />} />
      </Route>
      <Route path="/dashboard" element={<DashboardLayout />} >
        <Route path="/dashboard/users" index element={ <UserScreen />} />
        <Route path="/dashboard/users/create" element={<AddUserScreen />} />
        <Route path="/dashboard/users/:id/" element={ <DetailUserScreen />} />
        <Route path="/dashboard/users/:id/edit" element={ <EditUserScreen />} />
        <Route path="/dashboard/clients-manager" element={ <ClientsScreen />} />
        <Route path="/dashboard/clients-manager/create" element={ <AddClientScreen />} />
        <Route path="/dashboard/clients-manager/:id" element={ <DetailClientScreen />} />
        <Route path="/dashboard/clients-manager/:id/edit" element={ <EditClientScreen />} />
       
      </Route>
    </Routes>
  );
}

export default AppRoutes;
