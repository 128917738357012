import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import theme from "themes/theme";
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import { Box, keyframes, styled } from "@mui/system";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { HeadsetMicOutlined } from "@mui/icons-material";
import FAQsIcon from '../../../../icons/menu-icons/FAQs';
import UsersManagerIcon from '../../../../icons/menu-icons/UsersManager';
import ClientsManagerIcon from '../../../../icons/menu-icons/SystemManager';
import DashboardIcon from '../../../../icons/menu-icons/Dashboard/DashborardIcon';
import DrawerFooter from "./styled/DrawerFooter";
import DrawerHeader from "./styled/DrawerHeader";
import DrawerStyled from "./styled/Drawer/DrawerStyled";
import MenuIcon from '@mui/icons-material/Menu';
import packageJson from '../../../../../package.json';
import utils from "utils/api";

export type IconValues = 'dashboard' | 'users-manager' | 'clients-manager' | 'faqs' | 'support';

export type NavItemsIcons = {
	[key in IconValues]: () => JSX.Element;
};

const MenuOpenIconStyled = styled(MenuOpenIcon)`
    color: #ffffff;
`;
const LinkStyled = styled(NavLink)`
	color: #1A1A1A;
    width: 100%;
    text-decoration: none;
    opacity: .7;
    &.__active__item {
        opacity: 1;
        border-radius: 5px;
        background-color: #346092;
        border-radius: 5px;
    }
`;
const DividerStyled = styled(Divider)`
    width: 70%;
    margin: auto auto 0 auto;
`;
const ImageStyled = styled('figure')`
    margin: 0 auto;
    min-width: 140px;
`;
/* aniamtion styled */
const slideIn = keyframes`
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;
const BoxStyled = styled(Box)`
    flex: 1;
    display: flex;
    align-items: center;
    animation: ${slideIn} 0.5s forwards;
`;

export type DrawerProps = {
    setOpen: (value: boolean) => void;
    open?: boolean;
    handleDrawerClose: () => void;
}

const Drawer = ({ open, handleDrawerClose, setOpen }: DrawerProps) => {
    const [navItems,] = React.useState<{ text: string, path: string, icon: IconValues }[]>([
        { text: 'Gestión de Usuarios', path: '/dashboard/users', icon: 'dashboard' },
        { text: 'Alta de Usuario', path: '/dashboard/users/create', icon: 'users-manager'},
        { text: 'Gestión de Sistemas', path: '/dashboard/clients-manager', icon: 'clients-manager'},
        /* 
        { text: 'FAQs', path: '/faqs', icon: 'faqs' },
        { text: 'Soporte', path: '/support', icon: 'support'}, */
    ]);
    const { getUid } = utils;
    const navItemsIcon: NavItemsIcons = {
        dashboard: () => <DashboardIcon />,
        'users-manager': () => <UsersManagerIcon />,
        'clients-manager': () => <ClientsManagerIcon />,
        'support': () => <HeadsetMicOutlined />,
        'faqs': () => <FAQsIcon />,
    };
    const { pathname } = useLocation();
    return (
        <DrawerStyled variant="permanent" open={open} theme={theme}>
            <DrawerHeader>       
                <IconButton onClick={handleDrawerClose} sx={{ marginLeft: `${open ? '3.5rem' : '0'}` }}>
                    {!open ? <MenuIcon sx={{ color: '#ffffff', margin: '0 auto'}} /> : <MenuOpenIconStyled />}
                </IconButton>
                {open &&
                <BoxStyled>
                    <ImageStyled>
                        <img src='/assets/logo-left-menu.svg' alt="logo"/>
                    </ImageStyled>
                </BoxStyled>}
            </DrawerHeader>
            <List sx={{ paddingTop: '1.5rem'}}>
                {navItems.map((value, index) => (
                    <ListItem disablePadding sx={{padding: '0 .5rem'}} key={value?.toString() + getUid()}>
                        <LinkStyled to={value?.path} className={`${pathname === value.path ? "__active__item" : ''}`} state={{ titlePage: value.text }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                onClick={() => { setOpen(false) }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: '#ffffff' 
                                    }}
                                >
                                    { (!!index || index === 0) && navItemsIcon[value?.icon]() }
                                </ListItemIcon>
                                <ListItemText
                                    sx={{ color: '#ffffff', opacity: open ? 1 : 0 }}
                                    primary={value?.text} />
                            </ListItemButton>
                        </LinkStyled>
                    </ListItem>
                ))}
            </List>

            <DividerStyled />

            <DrawerFooter>
                V{ packageJson && packageJson?.version }
            </DrawerFooter>
        </DrawerStyled>
    );
}

export default Drawer;