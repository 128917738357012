import { useCallback, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { getClients, postCreateClients, deleteClient, putUpdateClient, getClientById  } from "services/clientsServices";

export type Client = {
    id: number | string;
    nombre: string;
    dominio: string;
    urlRespuesta: string
    activo: boolean;
    identificador: string;
    email: string;
    apiKey: string;
    observaciones?: string;
} & ClientFormProps;

export type ClientFormProps = {
    nombre: string;
    dominio: string;
    urlRedireccion: string;
    emailSoporte: string;
    observaciones?: string;
};

const useClientForm = () => {
    const [clients, setClients] = useState<Client[]>([]);
    const [client, setClient] = useState<Client | null>(null);

    const {
        register,
        setValue,
        formState,
        getValues,
        handleSubmit,
        trigger,
    } = useForm<Client>({ mode: 'all' });

    const {
        errors,
        isValid,
        isSubmitting
    } = formState;

    const getClientsCb = useCallback(
        async () => {
            const clientResponse = await getClients();
            if (!!clientResponse) {
                setClients(clientResponse.data as Client[]);
            }
            return clientResponse;
        }, []
    );
   /**
    * The function `handleGetClients` asynchronously calls the `getClientsCb` function.
    */
    const handleGetClients = async () => {
        const res = await getClientsCb();
        return res;
    };
    /**
     * The function `handleGetClientById` fetches a client by their ID and sets the client data in the
     * state.
     * @param {string | number} id - The `id` parameter in the `handleGetClientById` function is a
     * string or a number.
     * @returns The `handleGetClientById` function is returning the response object from the
     * `getClientById` function.
     */
    const handleGetClientById = async (id: string | number) => {
        const response = await getClientById(id);
        setClient(response?.data);
        return response;
    };
    /**
     * The function `handleCreateClient` is an asynchronous function that handles creating a client by
     * posting the client data to a server.
     * @param {ClientFormProps} data - The `data` parameter in the `handleCreateClient` function is of
     * type `ClientFormProps`, which likely contains the information needed to create a new client.
     * This data could include fields such as client name, contact information, address, etc.
     * @returns The `handleCreateClient` function is returning the response from the
     * `postCreateClients` function, which is the result of creating a new client based on the data
     * provided in the `ClientFormProps`.
     */
    const handleCreateClient = async (data: ClientFormProps) => {
        const response = await postCreateClients(data);
        return response;
    };
    /**
     * The function `handleUpdateClient` updates a client and returns a success message if the update
     * is successful.
     * @param {Client} row - The `row` parameter in the `handleUpdateClient` function is expected to be
     * an object representing a client. It is used as the input to update the client information in the
     * `putUpdateClient` function.
     * @returns If the response from `putUpdateClient(row)` contains a `success` property, the function
     * will return an object with `success` set to true, `alertMessage` set to "El cliente se actualizó
     * correctamente!", and `alertType` set to "success". Otherwise, it will return the response as it
     * is.
     */
    const handleUpdateClient = async (row: Client) => {
        const response = await putUpdateClient(row);
        const messageSuccess = "El cliente se actualizó correctamente!";
        if (!!response?.success) {
            return {
                success: true,
                alertMessage: response.alertMessage || messageSuccess,
                alertType: "success",
            };
        }
        return response;
    };
    /**
     * The function `handleDeleteClient` deletes a client by ID and returns a success message if the
     * deletion is successful.
     * @param {string | number} id - The `id` parameter in the `handleDeleteClient` function is a
     * string or number that represents the unique identifier of the client that needs to be deleted.
     * @returns If the response from the `deleteClient` function indicates success, the function will
     * return an object with the properties `alertMessage`, `alertType`, and `success` set accordingly.
     * If the response does not indicate success, the function will return the response object as is.
     */
    const handleDeleteClient = async (id: string | number) => {
        const response = await deleteClient(id);

        if (!!response?.success) {
            await handleGetClients();
            return {
                alertMessage: response.alertMessage || "Cliente eliminado correctamente!",
                alertType: "success",
                success: true
            };
        };
        return response;
    };

    return {
        form: {
            register,
            formState: {
                isValid,
                errors,
                isSubmitting
            },
            getValues,
            setValue,
            handleSubmit,
            trigger,
            useWatch
        },
        services: {
            handleCreateClient,
            handleGetClients,
            handleUpdateClient,
            handleDeleteClient,
            handleGetClientById
        },
        clients,
        client,
        setClient
    };
};

export default useClientForm;