import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Dialog, Grid } from '@mui/material';
import { AlertContext, AlertContextProps } from 'context/AlertContext/AlertProvidert';
import { AlertSeverityType } from 'hooks/useDisplayAlert';
import useUsers from 'hooks/useUsers';
import Camera from 'components/Users/AddUser/Camera';
import useUserForm from 'hooks/useUserForm';
import UserForm from 'components/UserForm';
import { UserFormInputs } from 'components/UserForm/UserForm';

const AddUserScreen = () => {
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const { handleCreateUser } = useUsers();
    const {
        formState,
        handleSubmit,
        register,
        onTakePicture,
        userImage,
        handleImageChange,
        setValue,
        watch
    } = useUserForm();
    const { errors, isSubmitting, isValid } = formState;
    const navigate = useNavigate();

    const onSubmit = async (data: UserFormInputs) => {

            const { alertType, alertMessage } = await handleCreateUser(data, userImage);
            if (alertType === "success") {
                setAlertData({ alertType: alertType as AlertSeverityType, alertMessage });
                navigate("/dashboard/users", { state: { titlePage: 'Gestión de Usuarios'}});
            } else {
                if (Array.isArray(alertMessage)) {
                    const messagges = alertMessage.flat(1);
                    setAlertData({ alertMessage: messagges, alertType: 'error' });
                } else {     
                    setAlertData({ alertMessage: alertMessage ||'Error en el servidor, por favor reintentar!' , alertType: 'error' });
                }
            }
    };
    
    return (
        <Grid container width="100%" margin="35px auto">
            <Dialog open={showCamera} fullScreen>
                <Camera
                    onClose={() => {
                        setShowCamera(false);
                    }}
                    onCancelar={() => setShowCamera(false)}
                    onTakePicture={onTakePicture}
                />
            </Dialog>

            {!showCamera &&
                <UserForm
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    handleClickOpenCamera={() => { setShowCamera(!showCamera); }}
                    handleImageChange={handleImageChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    onSubmit={onSubmit}
                    register={register}
                    userImage={userImage}
                    user={null}
                    mode="add"
                />}
        </Grid>
    )
};

export default AddUserScreen;