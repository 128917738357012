import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

const LeftSidePage: React.FC = () => {
	return (
		<Grid item xs={12} sm={7} style={{ backgroundImage: 'url("/assets/images/loginBackground.png")', backgroundSize: 'cover', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '100px' }}>
        
			<Typography variant="h1" color={'#ffff'} style={{ fontSize: '70px', textAlign: 'left', fontWeight: '300', fontFamily: "Roboto" }}>
				SRF
			</Typography>

			<Typography variant="h5" color={'#ffff'} style={{ marginTop: '25px', fontSize: '34px', textAlign: 'left', fontWeight: '700', fontFamily: "Roboto" }}>
				Sistema de Reconocimiento Facial
			</Typography>

		</Grid>
	);
}

const WrapperPagesOutlet: React.FC<{children: any}> = ({ children }) => {
	return (
		<Grid item xs={12} sm={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '50px' }}>{children}</Grid>
	);
}

const LayoutLogin: React.FC = () => {
    return (
        <Grid container spacing={0} style={{ height: '100vh' }}>
            {/* img left side */}
            <LeftSidePage />
            {/* wrapper of pages */}
            <WrapperPagesOutlet>
                <Outlet />
            </WrapperPagesOutlet>
        </Grid>
    );
}

export default LayoutLogin;