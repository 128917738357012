import styled from '@emotion/styled';
import { Box } from '@mui/system';

const BoxContainerImage = styled(Box)`
    height: 126px;
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .6s ease-in-out;
`;

export default BoxContainerImage