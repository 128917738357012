import styled from "@emotion/styled";
import { Grow } from "@mui/material";
import { Box } from "@mui/system";
import Button from "components/UI/Button";
import React, {
	useEffect,
	useRef,
	useState,
} from "react";
import CameraPermissions from "../CameraPermissions";

export type CameraProps = {
  onClose: () => void;
  onCancelar?: () => void;
  onSiguiente?: () => void;
  onTakePicture?: ({b64}: { b64: string}) => void;
};

const ContainerCameraView = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;
const SectionCameraView = styled(Box)`
	width: 600px;
	height: 400px;
	position: relative;
`;
const ContainerButtons = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;
const PreviewImageTaken = styled.img`
	border-radius: 26px;
	width: 600px;
	height: 400px;
	object-fit: cover;
`;

const Camera: React.FC<CameraProps> = ({
	onClose,
	onCancelar,
	onSiguiente,
	onTakePicture
}) => {
	const [isReady, setIsReady] = useState<boolean>(false);
  	const videoRef = useRef<HTMLVideoElement>(null);
	const [image, setImage] = useState<string | null>(null);

	const captureFrame = async () => {
		if (videoRef.current) {
			const canvas = document.createElement("canvas");
			canvas.width = videoRef.current.videoWidth;
			canvas.height = videoRef.current.videoHeight;
			const ctx = canvas.getContext("2d");
			if (ctx) {
				ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
				const dataURL = canvas.toDataURL("image/png");
				return  dataURL;
			}
		}
	};

	const handleClickCapture = async () => {
		if (!!image) {
			setImage(null);
			return;
		}
		try {
			const b64: string = await captureFrame() ?? "";
			onTakePicture && onTakePicture({ b64 });
			b64 && setImage(b64);
		} catch (e: any) {
			console.error({ e });
			throw new Error(e);
		}
  	};

	useEffect(() => {
		const current = videoRef?.current;
		const initializeCamera = async () => {
			try {
				const stream = await navigator?.mediaDevices?.getUserMedia({
					video: true,
				});
				if (current) {
					current.srcObject = stream;
				}
			} catch (error: any) {
				console.error("Error accessing camera:", error);
				throw new Error(error || "Camera access fail.");
			}
		};

		initializeCamera();
		
		return () => {
			if (current && current?.srcObject) {
				const stream = current?.srcObject as MediaStream;
				const tracks = stream?.getTracks();
				tracks.forEach((track) => {
					track.stop();
				});
			}
		};
	}, [image, isReady]);

	return (
		<ContainerCameraView>
			
			{
				!isReady && <CameraPermissions onPermissionGranted={() => { setIsReady(true) }} />
			}
			{
				isReady && <SectionCameraView>
					{
						image ? (
							<PreviewImageTaken src={image} alt="Captured image" />
						) : (
							<Grow in>
								<video
									style={{
										borderRadius: '26px',
										width: '600px',
										height: '400px',
										objectFit: 'cover',
									}}
									ref={videoRef} autoPlay muted></video>
							</Grow>
						)
					}
					<ContainerButtons>
						<Button onClick={() => {
							setImage(null);
							onCancelar && onCancelar();
							onTakePicture && onTakePicture({ b64: '' });
						}} variant="text" color="secondary" size="small" className="padding__buttons_custom">Cancelar</Button>
						<Button onClick={handleClickCapture} size="small" className="padding__buttons_custom"> {!!image ? 'Volver a capturar' : 'Capturar'} </Button>
						<Button onClick={onSiguiente || onClose} variant="text" color="primary" size="small" className="padding__buttons_custom">Siguiente</Button>
					</ContainerButtons>
				</SectionCameraView>
			}
		</ContainerCameraView>
	);
};

export default Camera;
