import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { useState} from 'react'

const UITooltip: React.FC<TooltipProps> = ({ children, placement, slotProps:spProps, ...props}) => {
    const [slotProps, setSlotProps] = useState<any>({
		popper: {
			sx: {
				[`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
				{
                    marginTop: '3px',
				}
			}
		}
    });
    return (
        <Tooltip {...props}
            slotProps={slotProps}
            placement={placement || "bottom"}>
            <span style={{ padding: '0 0 .2rem 0', margin: '0' }}>
                {children}
            </span>
        </Tooltip>
    );
}

export default UITooltip;