const UsersManagerIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1800_116)">
                <path d="M6.57899 11.85C7.37012 11.85 8.14348 11.6154 8.80128 11.1759C9.45907 10.7364 9.97176 10.1116 10.2745 9.38073C10.5773 8.64983 10.6565 7.84556 10.5021 7.06964C10.3478 6.29371 9.96683 5.58098 9.40742 5.02157C8.84801 4.46216 8.13528 4.0812 7.35936 3.92686C6.58343 3.77252 5.77917 3.85173 5.04826 4.15448C4.31736 4.45723 3.69264 4.96992 3.25312 5.62772C2.81359 6.28552 2.57899 7.05887 2.57899 7.85C2.57899 8.91087 3.00042 9.92828 3.75057 10.6784C4.50071 11.4286 5.51813 11.85 6.57899 11.85ZM6.57899 4.983C7.1457 4.98241 7.69983 5.14995 8.17128 5.46442C8.64272 5.77889 9.01027 6.22616 9.22741 6.74961C9.44455 7.27306 9.50152 7.84916 9.39111 8.405C9.2807 8.96084 9.00787 9.47143 8.60715 9.87215C8.20643 10.2729 7.69584 10.5457 7.14 10.6561C6.58416 10.7665 6.00805 10.7096 5.4846 10.4924C4.96115 10.2753 4.51389 9.90772 4.19942 9.43628C3.88494 8.96484 3.7174 8.4107 3.71799 7.844C3.71984 7.08578 4.02186 6.35915 4.558 5.82301C5.09415 5.28687 5.82078 4.98485 6.57899 4.983Z" fill="white" />
                <path d="M6.583 13.282C5.38663 13.2917 4.20402 13.5383 3.10348 14.0075C2.00293 14.4768 1.00625 15.1594 0.170998 16.016C0.116876 16.0691 0.0738811 16.1324 0.04453 16.2023C0.0151789 16.2722 6.10352e-05 16.3472 6.10352e-05 16.423C6.10352e-05 16.4988 0.0151789 16.5738 0.04453 16.6437C0.0738811 16.7136 0.116876 16.7769 0.170998 16.83C0.277178 16.9301 0.416182 16.988 0.561998 16.993C0.634802 16.9936 0.706982 16.9795 0.774183 16.9515C0.841384 16.9235 0.902212 16.8822 0.952998 16.83C1.67696 16.0732 2.54591 15.47 3.50803 15.0563C4.47014 14.6426 5.50572 14.4269 6.553 14.422C7.59934 14.4328 8.63312 14.6512 9.59441 15.0646C10.5557 15.4779 11.4254 16.078 12.153 16.83C12.2609 16.9379 12.4073 16.9986 12.56 16.9986C12.7127 16.9986 12.8591 16.9379 12.967 16.83C13.0749 16.7221 13.1356 16.5757 13.1356 16.423C13.1356 16.2703 13.0749 16.1239 12.967 16.016C12.1356 15.162 11.1436 14.4808 10.048 14.0116C8.95237 13.5424 7.77479 13.2944 6.583 13.282Z" fill="white" />
                <path d="M15.142 11.85C15.5925 11.85 16.033 11.7164 16.4076 11.4661C16.7822 11.2158 17.0742 10.86 17.2466 10.4438C17.419 10.0275 17.4641 9.56947 17.3762 9.12758C17.2883 8.68569 17.0714 8.27979 16.7528 7.96121C16.4342 7.64263 16.0283 7.42567 15.5864 7.33777C15.1445 7.24987 14.6865 7.29499 14.2702 7.4674C13.854 7.63982 13.4982 7.9318 13.2479 8.30641C12.9976 8.68103 12.864 9.12145 12.864 9.572C12.8658 10.1756 13.1064 10.7539 13.5332 11.1807C13.9601 11.6076 14.5384 11.8482 15.142 11.85ZM15.142 8.4C15.3673 8.4 15.5875 8.4668 15.7748 8.59196C15.9621 8.71711 16.1081 8.895 16.1943 9.10312C16.2805 9.31125 16.3031 9.54026 16.2591 9.76121C16.2152 9.98215 16.1067 10.1851 15.9474 10.3444C15.7881 10.5037 15.5852 10.6122 15.3642 10.6561C15.1433 10.7001 14.9142 10.6775 14.7061 10.5913C14.498 10.5051 14.3201 10.3591 14.195 10.1718C14.0698 9.98449 14.003 9.76427 14.003 9.539C14.0069 9.23822 14.1282 8.95087 14.341 8.73826C14.5538 8.52566 14.8412 8.40363 15.142 8.4Z" fill="white" />
                <path d="M19.829 15.137C18.969 14.3294 17.9164 13.7558 16.7716 13.471C15.6268 13.1862 14.4281 13.1996 13.29 13.51C13.2173 13.5271 13.1487 13.5583 13.0881 13.6019C13.0275 13.6455 12.976 13.7006 12.9367 13.764C12.8974 13.8275 12.8709 13.8981 12.8589 13.9718C12.8468 14.0455 12.8494 14.1208 12.8665 14.1935C12.8836 14.2662 12.9148 14.3348 12.9584 14.3954C13.002 14.456 13.0571 14.5075 13.1205 14.5468C13.184 14.5861 13.2546 14.6126 13.3283 14.6246C13.402 14.6367 13.4773 14.6341 13.55 14.617C14.502 14.3548 15.5056 14.3429 16.4635 14.5824C17.4215 14.8219 18.3014 15.3047 19.018 15.984C19.1242 16.0841 19.2632 16.142 19.409 16.147C19.5245 16.144 19.6367 16.1076 19.732 16.0424C19.8274 15.9771 19.9019 15.8857 19.9465 15.7792C19.9912 15.6726 20.0042 15.5554 19.9838 15.4417C19.9635 15.328 19.9108 15.2225 19.832 15.138L19.829 15.137Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_1800_116">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
};

export default UsersManagerIcon;