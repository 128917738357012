import React, { useState } from 'react';
import { Typography, Box, Grow } from '@mui/material';
import BoxContainerImage from '../BoxContainerImage';
import IconStyled from '../IconStyled';
import BoxContainerImageDotted from '../BoxContainerImageDotted';
import Tooltip from 'components/UI/Tooltip';

export type ImageUploadProps = {
	handleImageChange: (file: File | null) => void;
	register: any | null;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ handleImageChange, register }) => {
  const [, setPreviewImage] = useState<string | null>(null);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files && e.target.files[0];
		handleImageChange(file);
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					setPreviewImage(reader.result);
				}
			};
			reader.readAsDataURL(file);
		} else {
			setPreviewImage(null);
		}
	};

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		if (file) {
			handleImageChange(file);
			const reader = new FileReader();
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					setPreviewImage(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<BoxContainerImageDotted onDragOver={handleDragOver} onDrop={handleDrop}>
			<Grow in>
				<BoxContainerImage>
					<IconStyled type={"upload"} />
					{/* RIGHT BOX */}
					<Box alignItems="center" textAlign="center" margin="0 auto 0">
						<Typography marginBottom={2} variant="body2">Subir o arrastrar archivo aquí. *</Typography>
						<input type="file" accept=".png, .jpg" style={{ display: 'none' }} id="image-upload" {...register('imagen', {
							onChange
						})} />
						<label htmlFor="image-upload">
							<Box
								bgcolor="white"
								border="1px solid #D3D3D3"
								borderRadius="8px"
								p={1}
								textAlign="center"
								style={{ cursor: "pointer" }}
							>
								<Tooltip  title="Seleccionar imagen">
									<Typography variant="body1">Seleccionar imagen</Typography>
								</Tooltip>
							</Box>
						</label>
						<Typography sx={{ fontSize: '12px' }} variant="inherit">Formato admitido: .PNG, .JPG</Typography>
					</Box>
				</BoxContainerImage>
			</Grow>
		</BoxContainerImageDotted>
	);
};

export default ImageUpload;
