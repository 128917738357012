import React, { useState } from "react";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TablePagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Client } from "hooks/useClientForm";
import utils from "utils";

export type ClientTableProps = {
    rows: Client[];
    handleDetailClient: (row: Client) => void;
    handleDeleteClient: (row: Client) => void;
    handleUpdateClient: (row: Client) => void;
};

type BodyTableProps = Omit<ClientTableProps, "rows"> & { row: Client; };

const HeaderTable = () => {
    return (
        <React.Fragment>
            <Grid item xs={8} className="table-cell" textAlign="left">
                <p className="table-title">Nombre de Sistema</p>
            </Grid>

            {/* <Grid item xs={4} className="table-cell" textAlign="left">
                <p className="table-title">Mail Soporte</p>
            </Grid> */}

            <Grid item xs={4} className="table-cell" style={{ textAlign: "right" }}>
                <p className="table-title">Acciones</p>
            </Grid>
        </React.Fragment>
    );
};
const IconButtonStyled = styled(IconButton)`
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  pointer-events: auto;
`;
const BodyTable = ({
    row,
    handleUpdateClient,
    handleDeleteClient,
    handleDetailClient,
}: BodyTableProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Grid item xs={8} className="table-cell" textAlign="left">
                <p>{`${row.nombre}`}</p>
            </Grid>

            {/*  <Grid item xs={4} className="table-cell" textAlign="left">
                <p>{row.emailSoporte}</p>
            </Grid> */}

            {/* icon dots */}
            <Grid
                item
                xs={4}
                className="table-cell"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}
            >
                <IconButtonStyled
                    aria-controls={`actions-menu-${row?.id}`}
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                >
                    <MoreVertIcon />
                </IconButtonStyled>
                <Menu
                    id={`actions-menu-${row?.id}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleUpdateClient(row);
                            handleClose();
                        }}
                    >
                        {" "}
                        Editar sistema
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleDeleteClient(row);
                            handleClose();
                        }}
                    >
                        Eliminar sistema
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleDetailClient(row);
                            handleClose();
                        }}
                    >
                        Ver Detalle
                    </MenuItem>
                </Menu>
            </Grid>
        </React.Fragment>
    );
};

const rowsPerPageOptions = [5, 10, 15, 25];

const ClientsTable = ({
    rows,
    handleDeleteClient,
    handleDetailClient,
    handleUpdateClient,
}: ClientTableProps) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const { getUid } = utils;
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "92%", margin: "0 auto" }}
        >
            {/* HEADER */}
            <HeaderTable />
            
            {/* BODY */}
            <Grid container width="100%" margin="auto" display="flex" minWidth="100%" flexDirection="row">
                {rows &&
                    rows?.length > 0 &&
                    rows
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((row, index) => (
                            <BodyTable
                                row={row}
                                handleDeleteClient={handleDeleteClient}
                                handleDetailClient={handleDetailClient}
                                handleUpdateClient={handleUpdateClient}
                                key={index}
                            />
                        ))}
            </Grid>
            
            {/* PAGINATION */}
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={rows?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={
                    <span className="custom-label">Filas por página</span>
                }
                sx={{
                    marginLeft: "auto",
                    color: "#6E6E6E",
                    fonWeight: "700",
                }}
            />

        </Grid>
    );
};

export default ClientsTable;
