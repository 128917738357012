import { IResetPasswordPayload } from 'pages/ForgotPassword/hooks/useForgotPasswordHook';
import * as AxiosClient from '../services/clientAxiosBase';

const axios = AxiosClient.getInstanceAxios(false, false);
type AuthenticatePayload = { username: string, mail: string };

const postUserAuthentication = async (payload: AuthenticatePayload) => {
    try {
        const response = await axios.post(`/auth/forgot-password`, payload);
        return response;
    } catch (e: any) {
        throw new Error(e);
    }
};

const postResetPassword = async (payload: IResetPasswordPayload) => {
    try {
        const response = await axios.post(`/auth/confirm-password`, payload);
        return response;
    } catch (e: any) {
        throw new Error(e);
   }
}

export { postUserAuthentication, postResetPassword };