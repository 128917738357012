import { postResetPassword, postUserAuthentication } from "services/postResetPassword";

export type IResetPasswordPayload = {
    verificationCode: string;
    newPassword: string;
    username?: string
};
export type IAuthenticateUserPayload = {
    mail: string;
    username: string;
};

const useForgotPasswordHook = () => {

    async function handleSubmitAuthenticate(payload: IAuthenticateUserPayload) {
        try {
            const isValidEmail = await postUserAuthentication({
                mail: payload?.mail ?? '',
                username: payload?.username ?? ''
            });
            return isValidEmail;
        } catch (e: any) {
            throw new Error(e);
        }
    };

    async function handleSubmitResetPassword(payload: IResetPasswordPayload) {
        try {

            const resResetPassword = await postResetPassword(payload);
            return resResetPassword;
        } catch (e: any) {
            throw new Error(e);
        }
    };

    return {
        handleSubmitAuthenticate,
        handleSubmitResetPassword
    }
};
export default useForgotPasswordHook;