import styled from "@emotion/styled";
import { ErrorOutline } from "@mui/icons-material";
import { ReactNode } from "react";

const InputErrorLabel: React.FC<{ children: ReactNode, icon?: boolean, error?: boolean }> = ({ children, icon, error }) => {
    const ErrorLabel = styled.span`
        padding-top: .5rem;
        padding-bottom: 0.3rem;
        color: ${error ?'#d50000' : '#42a5f5'};
        font-size: 12px;
    `;
    const ErrorLabelWidthIcon = styled.span`
        color: #ba000d;
        display: flex;
        align-items: center;
        width: 100%;
    `;
    const ErrorMessage = styled.span`
        margin-left: 5px;
    `;
    return (
        <>
            {!icon ? (<ErrorLabel>{children}</ErrorLabel>) : (
                <ErrorLabelWidthIcon>
                    {/* icon [only error] */}
                    <ErrorOutline className="error-icon" />
                    {/* message */}
                    <ErrorMessage>{children}</ErrorMessage>
                </ErrorLabelWidthIcon>)}
        </>
    );
}
export default InputErrorLabel;