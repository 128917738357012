import { Grid } from '@mui/material';
import AddClientForm from 'components/Clients/AddClientForm';
import { AlertContext, AlertContextProps } from 'context/AlertContext/AlertProvidert';
import useClientForm from 'hooks/useClientForm';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { postCreateClients } from 'services/clientsServices';

const AddClientScreen = () => {
    const navigate = useNavigate();
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const {
        form: {
            handleSubmit,
            register,
            formState: {
                errors,
                isSubmitting,
                isValid
            }
        }
    } = useClientForm();
    // TODO: crear Formulario

    const handleOnClose = () => {
        navigate('/dashboard/clients-manager', { state: { titlePage: 'Gestión de Sistemas' } })
    };
    const onSubmit = async (data: any) => {
        const response = await postCreateClients(data);
        if (response?.success) {
            handleOnClose();
        }
        setAlertData(response);
    };
    return (
        <Grid container width="100%" margin="35px auto">
            <AddClientForm
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                errors={errors}
                isValid={isValid}
                handleOnClose={handleOnClose}
            />
        </Grid>
    );
};

export default AddClientScreen;