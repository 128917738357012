import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import userService from "services/userServices";
import utils, { MappedUsersAutocomplete } from '../utils/api';
import { SearchInputProp } from "components/Users/InputSearch/InputSearch";
import { UserFormInputs } from "components/UserForm/UserForm";

export type UserClientType = {
    id: string;
    nombre: string;
};

export type UserType = {
    customId: string;
    sistema: UserClientType;
    nombre: string;
    apellido: string;
    cuit: string;
    cliente: string;
    userId: string;
    imagen: any | FileList;
    dni: string;
    foto: string;
    legajo: string;
    cuil?: string;
    userID?: string;
};

const useUsers = () => {

    const [users, setUsers] = useState<UserType[]>([]);
    const [userSelected, setUserSelected] = useState<UserType | null>(null);
    const [autocompleteData, setAutocompleteData] = useState<MappedUsersAutocomplete[]>([]);
    const { postSearchUsers, postUserService, getUsers, getUserById, postUpdateUser, deleteUser } = userService;
    const { mapperUsersAutocompleteView } = utils;

    const {
        register,
        formState: {
            isValid,
        },
        reset,
        handleSubmit,
    } = useForm<UserFormInputs>({ mode: 'all' });

    const handleGetUsers = useCallback(async () => {
        const response = await getUsers();
        if (response?.data) {
            setUsers(response?.data || []);
        }
        return response;
    }, [getUsers]);

    const handleGetUserById = async (payload: { id: string }) => {
        try {
            const { id } = payload;
            const response = await getUserById({ id });
            setUserSelected(response?.data);
            return response?.data;
        } catch (error: any) {
            throw new Error(error);
        }
    };
    const handleCreateUser = async (data: UserFormInputs, userImage: File | null) => {
        try {
            const response = await postUserService(
                data,
                userImage
            );
            !!response && await handleGetUsers();
            return response;
        } catch (error: any) {
            throw new Error(error);
        }
    };
    const handleUpdateUser = async (data: UserType, userImage: File | null) => {
        const body = {
            ...data,
            imagen: userImage ?? (data.imagen[0] ?? null)
        }
        try {
            const userUpdated = await postUpdateUser(body, userImage || body?.imagen);
            if (!!userUpdated) {
                await handleGetUsers();
            }
            return userUpdated;
        } catch (error: any) {
            throw new Error(error);
        }
    };
    // automplete data
    const handleSearchUsers = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e.target) {
                const { value } = e?.target;
                if (!!value && (value?.length % 3) === 0) {
                    const response = await postSearchUsers(value);
                    const mapper = mapperUsersAutocompleteView(response?.data.usuarios);
                    setAutocompleteData(mapper);
                }
            }
        } catch (e: any) {
            throw new Error(e);
        }
    };
    const handleSelectSerachedUser = async (e: SearchInputProp) => {
        if (!(e.search === "")) {
            const search = await postSearchUsers(e.search);
            const filtered = search?.data?.usuarios;
            setUsers(filtered); 
        } else {
            await handleGetUsers();
            return;
        }
    };
    const handleDeleteUser = async ({ id }: { id: string | number }) => {
        try {
            const response = await deleteUser({ id });
            if (!!response) {
                await handleGetUsers();
            }
            return response;
        } catch (error: any) {
            throw new Error(error);
        }
    };

    return {
        users,
        userSelected,
        handleGetUsers,
        setUserSelected,
        autocompleteData,
        handleDeleteUser,
        handleCreateUser,
        handleUpdateUser,
        handleGetUserById,
        handleSearchUsers,
        handleSelectSerachedUser,
        form: {
            register,
            handleSubmit,
            isValid,
            reset
        }
    }
}
export default useUsers;