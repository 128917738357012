import { UserFormInputs } from "components/UserForm/UserForm";
import { useState } from "react";
import { useForm } from "react-hook-form";
import utils from "utils/api";

const useUserForm = () => {
    const [userImage, setUserImage] = useState<File | null>(null); 
    const {
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting },
		reset,
		trigger,
		setValue,
		getValues,
		watch
    } = useForm<UserFormInputs>({ mode: "all" });
    
	const handleImageChange = (file: File | null) => { setUserImage(file); };

	const onTakePicture = ({ b64 }: { b64: string }) => {
		if (!!b64) {
			const imgFile = utils?.dataURItoFile(b64, "captured-picture.jpeg");
			handleImageChange(imgFile);
			trigger();
			return;
		}
		handleImageChange(null);
	};

    return {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
        reset,
        userImage,
        onTakePicture,
		handleImageChange,
		setValue,
		trigger,
		getValues,
		watch
    }
};

export default useUserForm;