const FAQsIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_1800_132" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.790802 17.3447C0.790802 17.8106 1.16846 18.1882 1.63431 18.1882L8.8393 18.1882C8.89174 18.1882 8.94306 18.1834 8.99285 18.1743C9.15722 18.1835 9.32282 18.1882 9.48951 18.1882C14.2937 18.1882 18.1882 14.2937 18.1882 9.48952C18.1882 4.68536 14.2937 0.790817 9.48951 0.790817C4.68535 0.790817 0.790801 4.68536 0.790802 9.48952C0.790802 9.65622 0.79549 9.82181 0.804743 9.98619C0.795586 10.036 0.790802 10.0873 0.790802 10.1397L0.790802 17.3447Z" />
            </mask>
            <path d="M0.790802 17.3447L-0.395386 17.3447L0.790802 17.3447ZM8.99285 18.1743L9.05951 16.99L8.91784 16.982L8.77829 17.0077L8.99285 18.1743ZM0.804743 9.98619L1.97137 10.2007L1.99703 10.0612L1.98906 9.91952L0.804743 9.98619ZM0.790802 10.1397L1.97699 10.1397L0.790802 10.1397ZM1.63431 17.002C1.82357 17.002 1.97699 17.1555 1.97699 17.3447L-0.395386 17.3447C-0.395386 18.4657 0.513346 19.3744 1.63431 19.3744L1.63431 17.002ZM8.8393 17.002L1.63431 17.002L1.63431 19.3744L8.8393 19.3744L8.8393 17.002ZM8.77829 17.0077C8.79895 17.0039 8.81943 17.002 8.8393 17.002L8.8393 19.3744C8.96404 19.3744 9.08717 19.363 9.2074 19.3409L8.77829 17.0077ZM9.48951 17.002C9.34507 17.002 9.2017 16.998 9.05951 16.99L8.92618 19.3586C9.11275 19.3691 9.30057 19.3744 9.48951 19.3744L9.48951 17.002ZM17.002 9.48952C17.002 13.6386 13.6386 17.002 9.48951 17.002L9.48951 19.3744C14.9488 19.3744 19.3744 14.9488 19.3744 9.48952L17.002 9.48952ZM9.48951 1.977C13.6386 1.977 17.002 5.34047 17.002 9.48952L19.3744 9.48952C19.3744 4.03025 14.9488 -0.395371 9.48951 -0.395371L9.48951 1.977ZM1.97699 9.48952C1.97699 5.34047 5.34046 1.977 9.48951 1.977L9.48951 -0.395371C4.03023 -0.395371 -0.395386 4.03025 -0.395386 9.48952L1.97699 9.48952ZM1.98906 9.91952C1.98105 9.77733 1.97699 9.63397 1.97699 9.48952L-0.395386 9.48952C-0.395386 9.67846 -0.390071 9.86629 -0.37957 10.0529L1.98906 9.91952ZM1.97699 10.1397C1.97699 10.1596 1.97516 10.1801 1.97137 10.2007L-0.36188 9.77164C-0.383991 9.89186 -0.395386 10.015 -0.395386 10.1397L1.97699 10.1397ZM1.97699 17.3447L1.97699 10.1397L-0.395386 10.1397L-0.395386 17.3447L1.97699 17.3447Z" fill="white" mask="url(#path-1-inside-1_1800_132)"/>
            <path d="M10.3059 10.825C10.1582 11.2315 9.83408 11.5912 9.40165 11.5912H9.3405C8.90006 11.5912 8.53038 11.2284 8.60528 10.7944C8.61295 10.7499 8.62129 10.7068 8.6303 10.665C8.69037 10.402 8.79121 10.165 8.93281 9.95384C9.0787 9.7427 9.27179 9.53354 9.51208 9.32638C9.71375 9.15906 9.88968 8.9997 10.0399 8.84832C10.19 8.69693 10.308 8.54156 10.3939 8.3822C10.4797 8.21886 10.5226 8.0376 10.5226 7.8384C10.5226 7.60734 10.484 7.41611 10.4067 7.26472C10.3338 7.10935 10.2222 6.99183 10.072 6.91215C9.92615 6.83247 9.74164 6.79264 9.51851 6.79264C9.33401 6.79264 9.16237 6.83048 9.00361 6.90618C8.84484 6.97789 8.71397 7.08943 8.61099 7.24082C8.42143 7.53159 8.17829 7.8384 7.83119 7.8384H7.39964C6.9556 7.8384 6.57913 7.46379 6.71661 7.04156C6.78337 6.83652 6.87632 6.65196 6.99547 6.48787C7.25721 6.12932 7.60692 5.86439 8.04459 5.69308C8.48226 5.51779 8.97357 5.43015 9.51851 5.43015C10.1192 5.43015 10.6341 5.52178 11.0632 5.70504C11.4923 5.88431 11.8206 6.14924 12.048 6.49982C12.2754 6.84642 12.3891 7.26871 12.3891 7.76669C12.3891 8.11329 12.3162 8.42204 12.1703 8.69294C12.0244 8.95986 11.8334 9.20886 11.5975 9.43992C11.3615 9.67099 11.1019 9.91002 10.8187 10.157C10.5741 10.3602 10.4067 10.5733 10.3166 10.7964C10.313 10.8059 10.3094 10.8154 10.3059 10.825ZM8.3471 13.4377C8.3471 13.1828 8.4415 12.9716 8.6303 12.8043C8.8191 12.633 9.07226 12.5473 9.38979 12.5473C9.70302 12.5473 9.95404 12.633 10.1428 12.8043C10.3359 12.9716 10.4325 13.1828 10.4325 13.4377C10.4325 13.6847 10.3359 13.8939 10.1428 14.0652C9.95404 14.2365 9.70302 14.3222 9.38979 14.3222C9.07226 14.3222 8.8191 14.2365 8.6303 14.0652C8.4415 13.8939 8.3471 13.6847 8.3471 13.4377Z" fill="white"/>
        </svg>
    );
}

export default FAQsIcon;