import { styled } from "@mui/material";

const DrawerHeader = styled('div')(({ theme }: any) => ({
	display: 'flex',
	backgroundColor: '#124680',
	alignItems: 'center',
	justifyContent: 'center',
	width: '50%',
	padding: theme.spacing(0, 1),
	marginLeft: '.6rem',
	// necessary for content to be below app bar
	...theme?.mixins.toolbar,
}));

export default DrawerHeader;