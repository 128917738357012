import { useCallback, useMemo, useState } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useUserForm from 'hooks/useUserForm';
import useUsers, { UserType } from 'hooks/useUsers';
import UserFormEdit from 'components/UserForm/UserFormEdit';
import useDisplayAlert from 'hooks/useDisplayAlert';

const DetailUserScreen = () => {
    const [user, setUser] = useState<UserType | null>(null);
    const params = useParams() as { id: string };
    const navigate = useNavigate();
    const { handleGetUserById } = useUsers();
    const { setAlertData } = useDisplayAlert();
    const {
        setValue,
        register,
        watch
    } = useUserForm();

    const getUserById = useCallback(async (id: string) => {
        try {
            const response = await handleGetUserById({ id });

            if (response?.data) {
                setValue("nombre", response?.data?.nombre);
                setValue("apellido", response?.data?.apellido);
                setValue("cliente", response?.data?.sistema);
                setValue("cuit", response?.data?.cuil);
                setValue("legajo", response?.data?.legajo);
                setValue("dni", response?.data?.dni);
                setValue("userID", response?.data?.customId);
                setUser(response?.data);
            }
        } catch (e: any) {
            setAlertData({
                alertMessage: 'Error al cargar usuario',
                alertType: e?.response?.statusCode >= 500 ? 'warning' : 'error'
            });
            navigate('/dashboard/users', { state: { titlePage: 'Gestión de Usuarios'}});
        }
    }, [handleGetUserById, setAlertData, navigate]);

    useMemo(async () => {
        await getUserById(params.id);
    }, [params.id]);

    
    return (
        <Grid container width="100%" margin="35px auto">
            <Grid
                p={3}
                border="1px solid #DADCE0"
                borderRadius={1}
                margin="53px auto"
            >
                <Typography variant="h1" gutterBottom>
                    Datos de usuario
                </Typography>

                <Divider />

                <form encType="multipart/form-data">

                    <Typography variant="h2" margin="25px 0px">
                        Información Personal
                    </Typography>

                    {/* FORM DETAIL USER */}
                    <UserFormEdit
                        watch={watch}
                        setValue={setValue}
                        register={register}
                        user={user}
                        handleClickOpenCamera={ () => {} }
                        handleImageChange={() => { }}
                        isValid={false}
                        userImage={null}
                        mode="detail"
                    />
                </form>
            </Grid>
        </Grid>
    )
};

export default DetailUserScreen;