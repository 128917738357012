import { UserType } from "hooks/useUsers";

export type MappedUsersAutocomplete = { label: string } & UserType;
export type ApplicationProperties = "usuario" | "sistema";
export type VerbAPI = "create" | "update" | "get" | "delete";

const isPasswordLengthValid = (value: string) => value.length >= 8;
const containsNumber = (value: string) => /\d/.test(value);
const containsUpperCase = (value: string) => /[A-Z]/.test(value);
const containsLowerCase = (value: string) => /[a-z]/.test(value);
const containsSpecialChar = (value: string) => (/[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/).test(value);

const getUid = () => {
    return 'xxxx-xxxx-4xxx-yxxx-xxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random()*16, v = c === 'x' ? r : (r&0x3);
        return v.toString(16);
    });
};
function dataURItoFile(dataURI: string, fileName: string) {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    return new File([blob], fileName, { type: "image/jpeg" });
};
function isValidArray(array: Array<any>): boolean {
    return (array && array.length > 0);
};
const mapperUsersAutocompleteView = (array: UserType[]): MappedUsersAutocomplete[] => {
    let mappedUsers: MappedUsersAutocomplete[] = [];
    if (array && isValidArray(array)) {
        mappedUsers = array.map((u,i) => ({
            ...u,
            userID: u.customId,
            label: `${u?.customId}`,
        }));
    }
    return mappedUsers;
};
const getAlertType = (value: number) => {
    if (value) {
        if (value < 400) return "success";
        if (value >= 400 && value < 500) return "error";
        if (value >= 500) return "warning";
    };
    return "warning";
};
const capitalized = (v: string) => v?.charAt(0)?.toUpperCase()?.concat(v?.slice(1));
const getAlertMessage = (t: VerbAPI, value: ApplicationProperties) => {
    switch (t) {
        case "create":
            return `${capitalized(value)} creado correctamente.`;
        case "update":
            return `${capitalized(value)} modificado correctamente.`;
        case "get": // No se muestran mensajes al obtener
            return `${capitalized(value)} obtenido correctamente.`;
        case "delete":
            return `${capitalized(value)} eliminado correctamente.`;
        default:// MENSAJE PUESTO PARA DETECTAR SI HAY ALGO QUE ESTA INCORRECTO
            return `${capitalized(value)} procesado correctamente.`;// no se encuentra un mesaje
    };
};
const manageAPIResponse = (status: number, type: ApplicationProperties = "sistema", message: string | null = null, verb: VerbAPI) => {
    let alertMessage = getAlertMessage(verb, type);
    return {
        success: status === 200 || status === 201 || status < 400,
        alertType: getAlertType(status),
        alertMessage: message || alertMessage
    };
};
const manageApiErrorResponse = (status: number, type: ApplicationProperties = "sistema", verb: VerbAPI, message?: string) => {
    let alertMessage = '';
    switch (status) {
        case 400:
            alertMessage = `Error al ${((v: VerbAPI) => {
                switch (v) {
                    case "create":
                        return "crear";
                    case "update":
                        return "modificar";
                    case "get":
                        return "obtener";
                    case "delete":
                        return "eliminar";
                    default:
                        return "procesar";
                }
            })(verb)} el ${type}. Por favor verifica los datos ingresados.`;
            break;
        case 500:
            alertMessage = `¡Ups! Estamos teniendo problemas en nuestro sistema, intenta más tarde.`;
            break;
        default:
            alertMessage = `A ocurrido un error. Por favor intenta de nuevo más tarde.`;
            break;
    };
    
    return {
        success: false,
        alertType: getAlertType(status),
        alertMessage: alertMessage || message // por ahora no voy a mapoear el error hasta que estandarizemos los mensajes
    };
};
const utils = {
    dataURItoFile,
    isPasswordLengthValid,
    containsNumber,
    containsUpperCase,
    containsLowerCase,
    containsSpecialChar,
    mapperUsersAutocompleteView,
    getUid,
    isValidArray,
    getAlertType,
    services: {
        manageAPIResponse,
        manageApiErrorResponse
    }
};

export default utils;
