import styled from "@emotion/styled";
import { Box } from "@mui/system";
import IconStyled from "../IconStyled";
import { Grow, IconButton, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import BoxContainerImage from "../BoxContainerImage";
import BoxContainerImageDotted from "../BoxContainerImageDotted";
import Tooltip from "components/UI/Tooltip";

export type ImageUploadedPoprs = {
	selectedFile: File | null;
	handleDeleteImage: (() => void) | undefined;
}

const BoxContentImageLoadedName =  styled(Box)`
    text-align: left;
    min-width: 130px;
    text-align: left;
    margin-left: 1rem;
    margin-bottom: .5rem;
`;

const ImageUploaded: React.FC<ImageUploadedPoprs> = ({ selectedFile, handleDeleteImage }) => {
	return (
		<BoxContainerImageDotted>
			<Grow in>
				<BoxContainerImage>
					<Box margin={"0 auto"} display="flex" alignItems="center">
						{/* Image loaded icon */}
						<IconStyled type={"loaded"} />
						{/* Text right side */}
						<BoxContentImageLoadedName sx={{ width: '100%' }}>
							<Typography variant="body1">{selectedFile?.name} </Typography>
							<Typography variant="body2">{selectedFile?.size} kb</Typography>
						</BoxContentImageLoadedName>
					</Box>
					{/* Delete icon */}
					<Box sx={{ marginLeft: 'auto' }} margin="0 auto">
						<IconButton onClick={handleDeleteImage}>
							<Tooltip title="Borrar/Quitar archivo">
								<DeleteIcon />
							</Tooltip>
						</IconButton>
					</Box>
				</BoxContainerImage>
			</Grow>
		</BoxContainerImageDotted>
	);
}

export default ImageUploaded;