import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useMemo, useState } from "react";
import { Dialog, Grid } from "@mui/material";
import useUsers, { UserType } from "hooks/useUsers";
import useUserForm from "hooks/useUserForm";
import { AlertContext, AlertContextProps } from "context/AlertContext/AlertProvidert";
import UserForm from "components/UserForm";
import Camera from "components/Users/AddUser/Camera";

const EditUserScreen = () => {
    const [user, setUser] = useState<UserType | null>(null);
    const [showCamera, setShowCamera] = useState<boolean>(false);
    const params = useParams() as { id: string};
    const { handleUpdateUser, handleGetUserById } = useUsers();
    const {
        setValue,
        register,
        formState,
        userImage,
        handleSubmit,
        onTakePicture,
        handleImageChange,
        watch
    } = useUserForm();
    
    const { errors, isSubmitting, isValid } = formState;
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const navigate = useNavigate();

    const getUserById = useCallback(async (id: string) => {
        try {
            const response = await handleGetUserById({ id });
            if (response?.data) {
                setValue("nombre", response?.data?.nombre);
                setValue("apellido", response?.data?.apellido);
                setValue("cliente", response?.data?.sistema);
                setValue("cuit", response?.data?.cuil);
                setValue("legajo", response?.data?.legajo);
                setValue("dni", response?.data?.dni);
                setValue("userID", response?.data?.customId);
                setUser(response?.data);
            }
        } catch (e: any) {
            setAlertData({
                alertMessage: 'Error al cargar usuario',
                alertType: e?.response?.statusCode >= 500 ? 'warning' : 'error'
            });
        }
    }, [handleGetUserById, setAlertData, navigate]);

    useMemo(async () => {
        await getUserById(params.id);
    }, [params.id]);

    const onSubmit = async (data: UserType) => {
        try {
            const { alertType, alertMessage } = await handleUpdateUser({...data, userId: params.id}, userImage);
            if (alertType === 'success') {
                setAlertData({ alertType, alertMessage });
                navigate("/dashboard/users", { state: { titlePage: 'Gestión de Usuarios'}});
            } else {
                if (Array.isArray(alertMessage)) {
                    const messagges = alertMessage.flat(1);
                    setAlertData({ alertMessage: messagges, alertType: alertType || 'error' });
                } else {     
                    setAlertData({ alertMessage: alertMessage ||'Error en el servidor, por favor reintentar!' , alertType: alertType || 'error' });
                }
            }
        } catch (e: any) {
            setAlertData({ alertMessage: 'Error en el servidor, por favor reintentar!', alertType: 'warning' });
        }
    };
    
    return (
        <Grid container width="100%" margin="35px auto">
            <Dialog open={showCamera} fullScreen>
                <Camera
                    onClose={() => {
                        setShowCamera(false);
                    }}
                    onCancelar={() => setShowCamera(false)}
                    onTakePicture={onTakePicture}
                />
            </Dialog>
            <UserForm
                setValue={setValue}
                watch={watch}
                errors={errors}
                handleClickOpenCamera={() => { setShowCamera(!showCamera); }}
                handleImageChange={handleImageChange}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                isValid={isValid}
                onSubmit={onSubmit}
                register={register}
                userImage={userImage}
                user={user}
                mode="edit"
            />
        </Grid>
    );
};

export default EditUserScreen;