import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import useValidationForm, { IFormFieldType } from '../../utils/hooks/useUtilsValidateForm';
import utils from 'utils/api';
import Step1 from 'components/ForgotPassword/Step1';
import Step3 from 'components/ForgotPassword/Step3';
import CustomAlert from 'components/UI/Alert/Alert';
import useForgotPasswordHook from './hooks/useForgotPasswordHook';

const ForgotPasswordScreen: React.FC = () => {

    const [step, setStep] = useState<1 | 2 | 3>(1);
    const [disabledStep1, setDisabledStep1] = useState<boolean>(true);
    const [disabledStep3, setDisabledStep3] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);
    const defaultFieldValue: IFormFieldType = { value: '', error: '' };
    const navigate: NavigateFunction = useNavigate();
    const { handleSubmitAuthenticate, handleSubmitResetPassword } = useForgotPasswordHook();
    const {
        values: step1Form,
        handleInputChange: handleInputChangeStep1Form,
        errors: errorsStep1Form,
        validateField: validateFieldStep1Form,
        validateForm: validateStep1Form
    } = useValidationForm({
        initialValues: {
            email: { ...defaultFieldValue },
            usuario: { ...defaultFieldValue },
        },
        validationRules: {
            email: (value: string) => {
                if (!value) return 'El campo email es requerido.';
                if (value) {
                    let isEmailValid = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/.test(value);
                    if (!isEmailValid) return 'Debe ser un email válido.';
                }
                return '';
            },
            usuario: (value: string) => (value || value.length > 0 ? '' : 'El campo usuario es requerido.')
        }
    });
    const {
        values: step3Form,
        errors: errorsStep3Form,
        handleInputChange: handleInputChangeStep3Form,
        validateField: valdiateFieldStep3Form,
        validateForm: validateStep3Form
    } = useValidationForm({
        initialValues: {
            token: { ...defaultFieldValue },
            newPassword: { ...defaultFieldValue },
            confirmNewPassword: { ...defaultFieldValue }
        },
        validationRules: {
            token: (value: string) => (value || value.length > 0 ? '' : 'El campo "token" es requerido.'),
            newPassword: (value: string) => {
                if (!value) return 'El campo "nueva contraseña" es requerido.';
                if (value && !containsUpperCase(value)) return 'Debe contener un valor en mayúscula.';
                if (value && !containsLowerCase(value)) return 'Debe contener un valor en minúscula.';
                if (value && !containsNumber(value)) return 'Debe contener al menos un número.';
                if (value && !containsSpecialChar(value)) return 'Debe contener al menos un caracter especial.';
                if (value && !isPasswordLengthValid(value)) return 'Debe contener al menos 8 carácteres.';
                return '';
            },
            confirmPassword: (value: string) => {
                if (!value) return 'El campo "confirmar contraseña" es requerido.';
                if (value && (value !== step3Form?.newPassword?.value)) return 'Deben coincidir las contraseñas.';
                return '';
            },
        }
    });

    const { containsLowerCase, containsNumber, containsSpecialChar, containsUpperCase, isPasswordLengthValid } = utils;
    
    useEffect(
        () => {
            const step1Conditions = [
                errorsStep1Form?.email?.error === '',
                errorsStep1Form?.usuario?.error === ''
            ]; 
            const step3Conditions = [
                errorsStep3Form?.token?.error === '',
                errorsStep3Form?.newPassword?.error === '',
                errorsStep3Form?.confirmPassword?.error === '',
            ];

            if (step === 1 && step1Conditions.every(v => v === true)) {
                setDisabledStep1(false);
            } else {
                step === 1  && setDisabledStep1(true);
            }
            if (step === 3 && step3Conditions.every(v => v === true)) {
                setDisabledStep3(false);
            } else {
                step === 3 && setDisabledStep3(true);
            }
        }, [
            step,
            errorsStep1Form?.email?.error,
            errorsStep1Form?.usuario?.error,
            errorsStep3Form?.token?.error,
            errorsStep3Form?.newPassword?.error,
            errorsStep3Form?.confirmPassword?.error
        ]
    );
    const handleOnClickStep1 = async () => {
        try {
            const body = {
                mail: step1Form?.email?.value,
                username: step1Form?.usuario?.value
            };
            await handleSubmitAuthenticate({ ...body });
            setStep(2);
        } catch (error: any) {
            console.error({ error });
            setErrors([...errors, error?.message]);
            setStep(1);
        }
    };

    const handleOnClickStep3 = async () => {
        try {
            const body = {
                verificationCode: step3Form?.token?.value,
                newPassword: btoa(step3Form?.newPassword?.value),
                username: step1Form?.usuario?.value
            };
            await handleSubmitResetPassword({ ...body });
            navigate('/login', { state: { titlePage: 'Iniciar Sesión'}});
        } catch (error: any) {
            console.error({ error });
            setErrors([...errors, error?.message]);
            setStep(1);
        }
    };
    const handleOnClickBack = () => navigate('/', { state: { titlePage: 'Iniciar Sesión'}});

    return (
        <form style={{ width: '100%'}}>
            <Grid container width={'100%'}>
                {
                    step === 1 && <Step1
                        validateForm={validateStep1Form}
                        values={step1Form}
                        handleInputChange={handleInputChangeStep1Form}
                        errors={errorsStep1Form}
                        validateField={validateFieldStep1Form}
                    />
                }
                {
                    step === 2 &&
                    <Grid container spacing={4} marginLeft={'1rem'}>
                        <Typography variant='h6' marginBottom={2}> <strong>¡Listo!</strong> </Typography>
                        <Typography>Te enviamos un correo a <strong>{ step1Form?.email?.value || 'correo@correo.com' }</strong> con las instrucciones para restablecer tu contraseña.</Typography>
                    </Grid>
                }
                {
                    step === 3 && <Step3
                        values={step3Form}
                        errors={errorsStep3Form}
                        handleInputChange={handleInputChangeStep3Form}
                        validateField={valdiateFieldStep3Form}
                        validateForm={validateStep3Form}
                    />
                }
                {/* container buttons */}
                <Grid item xs={10} margin="2rem auto auto">
                    {
                        step === 1 && <Button
                            style={{margin: 0}}
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleOnClickStep1}
                            disabled={disabledStep1}> Enviar </Button>
                    }
                    {
                        step === 2 && <Button
                            style={{margin: 0}}
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => setStep(3)}> Continuar </Button>
                    }
                    {
                        step === 3 && <Button
                            variant='contained'
                            style={{margin: 0}}
                            color='primary'
                            fullWidth
                            onClick={handleOnClickStep3}
                            disabled={disabledStep3}> Restablecer contraseña </Button>
                    }
                    {
                        (step === 1 || step === 3) && <Button
                            variant='text'
                            style={{margin: '1rem auto'}}
                            type='button'
                            fullWidth color='secondary'
                            onClick={handleOnClickBack}> {step === 3 ? 'Cancelar' : 'Volver'} </Button>   
                    }
                </Grid>
                { errors && <CustomAlert severity={'warning'} open={Boolean(errors[0])} message={errors[0]} onClose={() => setErrors([])} /> }
            </Grid>
        </form>
    );
};

export default ForgotPasswordScreen;
