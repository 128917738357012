import React from 'react';
import { Button } from '@mui/material';

interface ButtonLoginFormProps {
  onClick: () => void;
  isDisabled: boolean;
  viewValue?: string;
  color?: "primary" | "secondary" | "inherit" | "warning" | "info" | "success" | "error";
  variant?: "contained" | "outlined" | "text";
};

const ButtonLoginForm: React.FC<ButtonLoginFormProps> = ({ onClick, isDisabled, viewValue, ...props}) => {
  return (
    <Button 
      variant={props.variant ?? "contained"} 
      color={props.color ?? "primary"} 
      fullWidth 
      onClick={onClick}
      sx={{ borderRadius: '25px', padding:'15px' }}
      disabled={isDisabled} 
    >
      { viewValue?.toLowerCase() ?? "Ingresar" }
    </Button>
  );
}

export default ButtonLoginForm;
