import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/system";
import { UserFormProps } from "../UserForm";
import ClientSelection from "../components/ClientSelection";
import InformationImagePicture from "../components/InformationImagePicture/InformationImagePicture";
import Tooltip from "components/UI/Tooltip";
import Button from "components/UI/Button";
import ContainerButtonsForm from "../components/ContainerButtonsForm";

type PickedUserFormProps = "register" | "user" | "handleImageChange" | "userImage" | "handleClickOpenCamera" | "isValid" | "mode" | 'watch' | 'setValue';
export type UserFormEditProps = Pick<UserFormProps & { watch: any, setValue: any}, PickedUserFormProps>;

const ButtonStyledViewImage = styled(Button)`
    margin: 0 auto;
`;

const UserFormEdit: React.FC<UserFormEditProps> = ({
    user,
    mode,
    watch,
    isValid,
    register,
    setValue,
    userImage,
    handleImageChange,
    handleClickOpenCamera,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [disabledFields] = useState<boolean>(mode === 'detail' || mode === 'add');
    const navigate = useNavigate();

	const handleOnClose = () => {
		navigate("/dashboard/users", { state: { titlePage: 'Gestión de Usuarios'}});
    };
    const handleShowImage = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Grid container spacing={4}>
            {/* Nombre */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    id="nombre"
                    label="Nombre"
                    variant="outlined"
                    type="text"
                    fullWidth
                    required
                    {...register("nombre", { required: true, minLength: 1, disabled: disabledFields })}
                    InputLabelProps={{ shrink: Boolean(user?.nombre) }}
                />
            </Grid>
            {/* Apellido */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("apellido", { required: true, minLength: 1, disabled: disabledFields })}
                    InputLabelProps={{ shrink: Boolean(user?.apellido) }}
                    id="apellido"
                    label="Apellido"
                    variant="outlined"
                    type="text"
                    fullWidth
                    required
                />
            </Grid>
            {/* CUIT */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("cuit", {
                        disabled: disabledFields
                    })}
                    InputLabelProps={{ shrink: Boolean(user?.cuil) }}
                    id="cuit"
                    label="CUIL"
                    variant="outlined"
                    type="number"
                    fullWidth
                />
            </Grid>
            {/* User ID */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("userID", { required: true, minLength: 1, disabled: disabledFields })}
                    InputLabelProps={{ shrink: Boolean(user?.customId) }}
                    id="userID"
                    label="UserID *"
                    variant="outlined"
                    type="text"
                    fullWidth
                />
            </Grid>
            {/* DNI */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("dni", {
                        disabled: disabledFields
                    })}
                    InputLabelProps={{ shrink: Boolean(user?.dni) }}
                    id="dni"
                    label="DNI"
                    variant="outlined"
                    type="number"
                    fullWidth
                />
            </Grid>
            {/* info of picture */}
            <Grid item sm={12} md={12} lg={12} xs={12}>
                <Typography variant="h2">
                    Información del cliente/empresa
                </Typography>
            </Grid>

            {/* Numero de legajo */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <TextField
                    {...register("legajo", {disabled: disabledFields })}
                    InputLabelProps={{ shrink: Boolean(user?.legajo) }}
                    id="legajo"
                    label="Legajo"
                    variant="outlined"
                    type="text"
                    fullWidth
                />
            </Grid>

            {/* Select cliente */}
            <Grid item sm={12} md={6} lg={6} xs={12}>
                <ClientSelection register={register} errors={null} disabled={disabledFields} setValue={setValue} watch={watch} value={user?.sistema} />
            </Grid>

            {/* show image user */}
            {mode === "edit" &&
                <>
                    {/* Upload / Take picture */}
                    <ContainerButtonsForm
                        handleImageChange={handleImageChange}
                        selectedFile={userImage}
                        onClick={handleClickOpenCamera}
                        disabled={false}
                        register={register}
                    />
            
                </>}
            {user &&
                <Grid item sm={12} md={12} lg={12} xs={12} display="flex" justifyContent="center">
                    <Tooltip title="Ver imagen del usuario">
                        <ButtonStyledViewImage variant="text" onClick={handleShowImage}>
                            <Typography variant="h2">
                                <small>Ver Imagen</small>
                            </Typography>
                        </ButtonStyledViewImage>
                    </Tooltip>
                </Grid>}

            {showModal &&
                <Dialog open={showModal} onClose={handleCloseModal}>
                <DialogTitle>
                    {user?.customId}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <picture>
                        <img src={user?.foto} alt="Imagen en modal" style={{ width: '100%', height: 'auto' }} />
                    </picture>
                </DialogContent>
                </Dialog>}

            {/* Instrucciones de la imagen */}
            <Grid item sm={12} md={12} lg={12} xs={12}>
                <InformationImagePicture />
            </Grid>

            {/* Container buttons 'enviar' y 'cancelar' */}
            <Grid item sm={12} md={12} lg={12} xs={12} textAlign="right">
                <Tooltip title={`${mode === 'edit' ? 'Cancelar Edicion' : 'Cancelar'}`}>
                    <Button variant="outlined" color="secondary" onClick={handleOnClose}>
                        Cancelar
                    </Button>
                </Tooltip>

                {mode === "edit" && <Tooltip
                    title="Editar Usuario"
                    disableHoverListener={!isValid}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!isValid}
                    >
                        Editar Usuario
                    </Button>
                </Tooltip>}

            </Grid>
        </Grid>
    );
};

export default UserFormEdit;