import { useState } from 'react';

export type IFormFieldType = {
    value: string;
    error: string;
};
export type IFormState = {
    [key: string]: IFormFieldType;
};
export type IValidationRules = {
    [key: string]: (value: string) => string;
};
export type IUseValidationFormProps = {
    initialValues: IFormState;
    validationRules: IValidationRules;
};
export type IUseFormResponse = {
    values: IFormState;
    errors: IFormState;
    handleInputChange: (fieldName: string, value: string) => void;
    validateField: (fieldName: string) => void;
    validateForm: () => boolean;
};

const useValidationForm = ({ initialValues, validationRules }: IUseValidationFormProps): IUseFormResponse => {

    const [values, setValues] = useState<IFormState>(initialValues);
    const [errors, setErrors] = useState<IFormState>({});

    /**
     * The function `handleInputChange` updates the values in a state object with a new value for a
     * specific field name.
     * @param {string} fieldName - fieldName is a string that represents the name of the input field
     * being updated.
     * @param {string} value - The `value` parameter in the `handleInputChange` function represents the
     * new value that is being input or updated for a specific field in a form.
     * @exmple
     * <input type=‘text’ name='username' value={username} onChange={handleInputChange('username', e.target.value) />
     */
    const handleInputChange = (fieldName: string, value: string): void => {
        setValues(prevValues => ({ ...prevValues, [fieldName]: { value, error: '' } }));
    };
    /**
     * The function `validateField` takes a field name as input, applies a validation rule to the
     * corresponding value, and updates the errors state accordingly.
     * @param {string} name - The `name` parameter in the `validateField` function is a string that
     * represents the name of the field being validated.
     */
    const validateField = (name: string) => {
        const error = validationRules[name](values[name]?.value);
        setErrors(prevErrors => ({ ...prevErrors, [name]: { ...prevErrors[name], error } }));
    };
    /**
     * The `validateForm` function iterates through validation rules for form fields, sets errors if
     * any, and returns a boolean indicating overall form validity.
     * @returns The `validateForm` function is returning a boolean value `isValid`, which indicates
     * whether the form validation was successful (`true`) or not (`false`).
     */
    const validateForm = () => {
        let isValid = true;
        for (const name in validationRules) {
            const error = validationRules[name](values[name].value);
            if (error !== '') {
                setErrors({ ...errors, [name]: { ...values[name], error } });
                isValid = false;
            }
        };
        return isValid;
    };
    
    return {
        values,
        errors,
        handleInputChange,
        validateForm,
        validateField
    };
};

export default useValidationForm;