import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LightbulbIcon from "@mui/icons-material/Lightbulb";

const InformationImagePicture = () => {
	return (
		<Box border="1px solid #CCCCCC" borderRadius="16px" p={2}>
			<Typography
				variant="h4"
				gutterBottom
				style={{ marginLeft: "100px" }}
			>
				La fotografía debe ser:
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={1}>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						width={50}
						height={50}
						bgcolor="#E2F7FE"
						borderRadius="4px"
					>
						<LightbulbIcon sx={{ color: "#3FB6DC", fontSize: 24 }} />
					</Box>
				</Grid>
				<Grid item xs={3}>
					<Box>
						<div>
							<ul>
								<li>Nítida</li>
								<li>Buena iluminación.</li>
							</ul>
						</div>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box>
						<div>
							<ul>
								<li>No debe tener barbijos, bufanda o lentes.</li>
								<li>Debe haber una sola persona.</li>
							</ul>
						</div>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
};

export default InformationImagePicture;