import { createTheme, ThemeOptions } from '@mui/material/styles';
const OBJECT_THEME = {
  typography: {
    fontFamily: [
      'Adobe Clean',
      'Roboto',
      'Segoe UI',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',      
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '27.54px',
      color: '#4B4B4B',
    },
    h2: {
      fontWeight: 700,
      fontSize: '19px',
      lineHeight: '23.79px',
      color: '#505050',
    },
    h4:{
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '22.5px',
      color: '#1A1A1A',
    },
    h5:{
      fontWeight: 900,
      fontSize: '14px',
      lineHeight: '22.5px',
      color: '#1A1A1A',
    },
    h6:{
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '22.5px',
      color: '#1A1A1A',
    },
    body2:{
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20.3px',
      color: '#505050',
    }
    
  },
  palette: {
    primary: {
      main: '#1473E6', 
    },
    secondary: {
      main: '#124680', 
    },
    info: {
      main: '#F5F5F5', 
    },
    error: {
      main: '#E34850', 
    },
    success: {
      main: '#33AB84',
    },
    warning: {
      main: '#F29423'
    }
    /* default: {
      main: '#25265E',
    }, */
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedSecondary': {
            borderRadius: '46px', 
            borderColor: '#747474', 
            borderWidth: '2px',
          },
          '&.MuiButton-containedPrimary': { 
            borderRadius: '46px',
          },
          '&.MuiButton-containedSizeSmall': {
            padding: '.5rem 1.3rem'
          },
          '&.MuiButton-textSizeSmall': {
            padding: '.5rem 1.3rem'
          },
          borderRadius: '50px', 
          padding: '.5rem 1.3rem',
          margin: '8px',
          textTransform: 'capitalize'
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#DADCE0',
          marginTop:'15px',
          marginBottom:'25px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '0 0 3px 5px',
        },
      },
    },
  },
} as ThemeOptions
const theme = createTheme(OBJECT_THEME);

export default theme;
