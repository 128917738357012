import React from "react";
import {
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { UserType } from "hooks/useUsers";
import UserFormAdd from "./UserFormAdd";
import UserFormEdit from "./UserFormEdit";

export type UserFormInputs = {
	nombre: string;
	apellido: string;
	cuit: string;
	userID: string;
	dni: string;
	legajo: string;
	cliente: string;
	imagen: any;
};

export type UserFormProps = {
	isSubmitting: boolean;
	handleSubmit: any;
	onSubmit: any;
	register: any;
	errors: any;
	isValid: boolean;
	handleImageChange: (file: File | null) => void;
	userImage: File | null;
	handleClickOpenCamera: (value?: any) => void;
	user: UserType | null;
	mode: "edit" | "add" | "detail";
	watch: any;
	setValue:any;
};

const UserForm: React.FC<UserFormProps> = ({
	isSubmitting,
	handleSubmit,
	onSubmit,
	register,
	errors,
	handleImageChange,
	userImage,
	handleClickOpenCamera,
	isValid,
	mode,
	user,
	watch,
	setValue
}) => {

	return (
		<>
			{/* USER FORM*/}
			{!isSubmitting && (
				<Grid
					p={3}
					border="1px solid #DADCE0"
					borderRadius={1}
					margin="53px auto"
				>
					<Typography variant="h1" gutterBottom>
						Datos de usuario
					</Typography>

					<Divider />
					{/* FORM */}
					<form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
						<Typography variant="h2" margin="25px 0px">
							Información Personal
						</Typography>
						{/* FORM ADD USER */}
						{!user && mode === 'add' &&
							<UserFormAdd
								watch={watch}
								setValue={setValue}
								register={register}
								errors={errors}
								handleClickOpenCamera={handleClickOpenCamera}
								userImage={userImage}
								handleImageChange={handleImageChange}
								isValid={isValid}
							/>}
						{/* FORM EDIT USER */}
						{mode === 'edit' &&
							<UserFormEdit
								setValue={setValue}
								watch={watch}
								register={register}
								user={user}
								handleClickOpenCamera={handleClickOpenCamera}
								handleImageChange={handleImageChange}
								userImage={userImage}
								isValid={isValid}
								mode="edit"
							
							/>}
					</form>
				</Grid>
			)}
		</>
	);
};

export default UserForm;
