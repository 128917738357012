import { Button, Toolbar, Typography } from "@mui/material";
import { Box, keyframes, styled } from "@mui/system";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Tooltip from "components/UI/Tooltip";
import { Logout } from "@mui/icons-material";

export interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
export type AppbarProps = {
    open: boolean | undefined;
    handleLogout: () => void;
    displayName: string;
    titlePage: string;
}

const drawerWidth = 240;
const easingCustom = 'cubic-bezier(0.4, 0, 0.2, 1)';
const AppBarStyled = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open', })<AppBarProps>(({ theme, open }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: easingCustom,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...({
        boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.35);'
    })
}));
const LogoStyled = styled('img')`
    margin-left: 3rem;
    margin-right: 1.5rem;
    transition: all ease-in-out .6s;
`;
const ImgStyled = styled('img')`
    width: 40px; 
    margin-right: 10px; 
    border-radius: 70px;
    margin-left: 50px;
`;
const TypographyStyled = styled(Typography)`
    margin-right: 10px;
    color: #0f0f0f;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
`;
const ToolbarStyled = styled(Toolbar)`
    padding-left: 1.5rem !important;
    padding-right: 0;
    margin-right: 0;
    background-color: #ffffff;
`;
const slideOut = keyframes`
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;
const BoxStyled = styled(Box)`
    /* flex: 1; */
    display: flex;
    align-items: center;
    animation: ${slideOut} .5s forwards;
`;

const AppBar = ({ open, handleLogout, displayName, titlePage }: AppbarProps) => { 
    return (
        <AppBarStyled open={open} color='default' elevation={0} className="__app__bar">
            <ToolbarStyled>
                <Box display="flex" alignItems="center" width="100%">
                    {!open &&
                    <BoxStyled>
                        <LogoStyled src="/assets/logo-appbar.svg" alt="logo" />
                    </BoxStyled>
                    }
                    <Box flex={5} display="flex" alignItems="center" justifyContent="left">
                        <Typography variant="h6">{ titlePage }</Typography>
                    </Box>
                    <Box flex={2} display="flex" alignItems="center" justifyContent="flex-end">
                        <TypographyStyled variant="body1">
                            {displayName + '\n'} <br />
                            <span style={{ color: '#787993', fontWeight: '400', fontSize: '15px' }}>Rol</span>
                        </TypographyStyled>
                        <ImgStyled src="/assets/images/user.png" alt="logo" />
                        <Tooltip title="Salir">
                            <Button color="primary" startIcon={<Logout />} onClick={handleLogout} />
                        </Tooltip>
                    </Box>
                </Box>
            </ToolbarStyled>
        </AppBarStyled>
    );
}

export default AppBar;