import React from 'react';
import { Typography, Box } from '@mui/material';
import LoginForm from '../../components/LoginForm/LoginForm';

const LoginScreen: React.FC = () => {
	return (
		<Box textAlign="center">
			{/* TODO Prefetch para carga de imagen */}
			<img src="/assets/images/loginIcon.png" alt="Login Icon" />

			<Typography variant="h4" color={'#124680'} style={{ marginTop: '15px', fontWeight: '700', fontSize: '27px', fontFamily: "Segoe UI" }}>
				Backoffice
			</Typography>

			<Typography color={'#1473E6'} style={{ fontWeight: '400', marginTop: '35px', padding: '15px' }}>
				Inicia sesión con tus credenciales
			</Typography>

			<LoginForm />
		</Box>
	);
}

export default LoginScreen;