import { Button, Divider, Grid, styled, TextareaAutosize, TextareaAutosizeProps, TextField, Typography } from '@mui/material';
import InputErrorLabel from 'components/UI/InputErrorLabel';
import Tooltip from 'components/UI/Tooltip';
import { Client } from 'hooks/useClientForm';
import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

export type AddClientFormProps = {
    handleSubmit: any;
    onSubmit: (value: any) => void;
    register: UseFormRegister<Client>;
    isSubmitting: boolean;
    errors: FieldErrors<Client>;
    isValid: boolean;
    handleOnClose: () => void;
};
const Textarea = React.forwardRef( (props: TextareaAutosizeProps & React.RefAttributes<Element>) => {
    return <TextareaAutosize minRows={3} {...props} />;
});
const WrapperForm = styled(Grid)`
    padding: calc(3 * .6rem);
    border: 1px solid #DADCE0;
    border-radius: 5px;
    margin: 53px auto;
`;

const AddClientForm = ({ handleSubmit, onSubmit, isSubmitting, register, errors, isValid, handleOnClose }: AddClientFormProps) => {

    return (
        <>
            {!isSubmitting && <WrapperForm>
                <Typography variant="h1" gutterBottom>
                    Datos del Sistema
                </Typography>

                <Divider />

                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                    <Typography sx={{ marginBottom: '1rem' }} variant="h2" >
                        Información de Contacto
                    </Typography>
                    <Grid container spacing={4}>
                        {/* NOMBRE */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                {...register("nombre", { required: true, minLength: 1  })}
                                id="nombre"
                                label="Nombre"
                                variant="outlined"
                                placeholder="Nombre"
                                type="text"
                                fullWidth
                                required
                                error={!!errors.nombre}
                                helperText={errors?.nombre &&
                                    <InputErrorLabel icon error>
                                        El nombre es requerido.
                                    </InputErrorLabel>}
                            />
                        </Grid>
                        {/* DOMINIO  */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                {...register("dominio", { required: true, minLength: 1  })}
                                id="dominio"
                                label="Dominio"
                                variant="outlined"
                                placeholder="Dominio"
                                type="text"
                                required
                                fullWidth
                                error={!!errors.dominio}
                                helperText={errors?.dominio &&
                                    <InputErrorLabel icon error>
                                        El dominio es requerido.
                                    </InputErrorLabel>}
                            />
                        </Grid>
                        {/* URL REDIRECCION */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                {...register("urlRedireccion", { required: true, minLength: 1  })}
                                id="urlRedireccion"
                                label="Url Redirección"
                                variant="outlined"
                                placeholder="Url Redirección"
                                type="text"
                                required
                                fullWidth
                                error={!!errors.urlRedireccion}
                                helperText={errors?.urlRedireccion &&
                                    <InputErrorLabel icon error>
                                        La url de redirección es requerida.
                                    </InputErrorLabel>}
                            />
                        </Grid>
                        {/* CORREO ELECTRONICO */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                {...register("emailSoporte", { required: true, minLength: 1  })}
                                id="emailSoporte"
                                label="Mail Electrónico"
                                variant="outlined"
                                placeholder="Mail Electrónico (soporte)"
                                type="email"
                                required
                                fullWidth
                                error={!!errors.emailSoporte}
                                helperText={errors?.emailSoporte &&
                                    <InputErrorLabel icon error>
                                        El correo electrónico es requerido.
                                    </InputErrorLabel>}
                            />
                        </Grid>
                        {/* OBSERVACIONES */}
                        <Grid item sm={12} md={12} lg={12} xs={12}>
                            <TextField
                                {...register("observaciones")}
                                id="observaciones"
                                label="Observaciones"
                                placeholder="Observaciones"
                                multiline
                                fullWidth
                                InputProps={{
                                    inputComponent: Textarea
                                }}
                            />
                        </Grid>
                        {/* Container buttons 'enviar' y 'cancelar' */}
                        <Grid item sm={12} md={12} lg={12} xs={12} textAlign="right">
                            <Tooltip title="Cancelar creacion de sistema">
                                <Button variant="outlined" color="secondary" onClick={handleOnClose}>
                                    Cancelar
                                </Button>
                            </Tooltip>

                            <Tooltip
                                title="Crear nuevo sistema"
                                disableHoverListener={!isValid}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={!isValid}
                                >
                                    Crear Sistema
                                </Button>
                            </Tooltip>
                        </Grid>

                    </Grid>
                </form>
            </WrapperForm>}
        </>
    );
};

export default AddClientForm;