import { Button, Grid, Typography } from "@mui/material";
import ImageUpload from "../ImageUpload";
import ImageUploaded from "../ImageUploaded";
import Tooltip from "components/UI/Tooltip";
import { CameraAlt } from "@mui/icons-material";
import styled from "@emotion/styled";

export type ContainerButtonsProps = {
	handleImageChange: (file: File | null) => void;
	selectedFile: File | null;
	register: any | null;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonCamera = styled(Button)`
    border: 1px solid #ccc;
    background-color: transparent;
    color: #505050;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    margin: 1.8rem auto 0 auto;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    transition: all ease 0.7s;
    &:hover {
      cursor: pointer;
    }
` as any;

const ButtonTakePicture: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...props }) => {
	return (
		<Tooltip
			title="Abrir cámara"
			disableHoverListener={props.disabled}
		>
			<ButtonCamera {...props}>
				<CameraAlt style={{ margin: "0 5px 0 0" }} />
				Tomar fotografía
			</ButtonCamera>
		</Tooltip>
	);
};

const ContainerButtonsForm: React.FC<ContainerButtonsProps> = ({ handleImageChange, selectedFile, register, ...props }) => {

	const handleDeleteImage = () => {
		handleImageChange(null);
	};

	return (
		<Grid
			item
			sm={12}
			xs={12}
			sx={{ textAlign: "-webkit-center" }}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			flexWrap="wrap"
			>
			{/* Field upload/drag & drop image || success loaded image */}
			
			{!selectedFile ? (
					<ImageUpload register={register} handleImageChange={handleImageChange} />
				) : (
					<ImageUploaded handleDeleteImage={handleDeleteImage} selectedFile={selectedFile} />
				)}
			
			{/* Button take picture */}
			<Grid margin={"0 auto"} item>
				<Typography className="__margin-mobile-top" width="100%" margin="0 auto" variant="body2">
					O puedes tomar una fotografia *
				</Typography>
				
				<ButtonTakePicture disabled={!!selectedFile} {...props} />

			</Grid>
		</Grid>
	);
};

export default ContainerButtonsForm