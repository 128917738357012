import { UsersScreenProps } from "pages/Users/UsersScreen";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const interceptorAuth = (WrappedComponent: React.FC<UsersScreenProps>) => {
  const InterceptorAuthComponent: React.FC = () => {
    const navigate = useNavigate();
    const [isValidToken, setIsValidToken] = useState<boolean | null>(null);

    useMemo(() => {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        navigate("/login", {state: { titlePage: 'Iniciar Sesión' }});
        return;
      }

      setIsValidToken(true);
    }, []);

    if (isValidToken === null) {
      return <div>Cargando...</div>;
    }

    return <WrappedComponent isValidToken={isValidToken} />;
  };

  return InterceptorAuthComponent;
};

export default interceptorAuth;
