import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import interceptorAuth from '../../interceptorAuth';
import { AlertContext, AlertContextProps, AlertProvider } from 'context/AlertContext/AlertProvidert';
import { useUser } from '../../context/UserProvider/UserProvider';
import CustomAlert from 'components/UI/Alert/Alert';
import Menu from 'components/Menu';
import { Box, styled } from '@mui/system';
import AppBar from 'components/Menu/components/AppBar';


interface DashboardLayoutProps {
  isValidToken: boolean;
}
const WrapperOutletStyled = styled(Box)`
    min-width: 400px;
    width: 100%;
    max-width: 921px;
    margin: 0 auto 5rem auto;
    flex-grow: 1;
`;
const Wrapper: React.FC<DashboardLayoutProps> = ({ isValidToken }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [titlePage, setTitlePage] = useState<string>('Gestión de Usuarios');
    const location = useLocation();
    const { username } = useUser();
	const navigate = useNavigate();
    const { alertData, setAlertData } = useContext(AlertContext) as AlertContextProps;
	const displayName = username ? username : "Invitado";
	
	const handleLogout = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('tokenExpiration');
		localStorage.removeItem('refreshToken');
		navigate('/login', { state:{ titlePage: 'Iniciar Sesión' }});
    };
    
    useEffect(
        () => {
            const { state } = location;
            setTitlePage(state?.titlePage || titlePage); 
        }, [location.pathname]
    );

    return (
        <Box minWidth="100%" sx={{ flexGrow: 1 }}>
            <AppBar displayName={displayName} open={open} handleLogout={handleLogout} titlePage={titlePage} />
            <Menu open={open} setOpen={setOpen} />
            <WrapperOutletStyled>
				<Outlet />
			</WrapperOutletStyled>
            {/* Alert message */}
            {alertData && (
                <CustomAlert
                    severity={alertData.alertType}
                    message={alertData?.alertMessage}
                    open={true}
                    onClose={() => setAlertData(null)}
                />
            )}
        </Box>
    );
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({isValidToken}) => {
    return (
        <AlertProvider>
            <Wrapper isValidToken={isValidToken} />
        </AlertProvider>
    );
}
export default interceptorAuth(DashboardLayout);
