import axios, { InternalAxiosRequestConfig } from "axios";

const setToken = (value: string) => {
  localStorage.setItem("accessToken", value);
};

const getToken = () => localStorage.getItem("accessToken");

const setRefreshToken = (value: string) => {
  localStorage.setItem("refreshToken", value);
};

const getRefreshToken = () => localStorage.getItem("refreshToken");
const getUsername = () => localStorage.getItem("username");
/**
 * The function `getInstanceAxios` creates an Axios instance with optional token and form data
 * handling.
 * @param {boolean} [withToken=true] - The `withToken` parameter is a boolean flag that determines
 * whether to include the access token in the request headers. If set to `true`, the access token will
 * be added to the `Authorization` header in the request.
 * @param {boolean} [formData] - The `formData` parameter in the `getInstanceAxios` function is a
 * boolean flag that determines whether the request should be sent with `Content-Type:
 * multipart/form-data` header. If `formData` is set to `true`, the request will be configured to send
 * data as `multipart/form-data`.
 * @returns The function `getInstanceAxios` returns an instance of Axios client with optional headers
 * based on the parameters `withToken` and `formData`.
 */
const getInstanceAxios = (
  withToken: boolean = true,
  formData: boolean = false
) => {
  let options = {
    baseURL: process.env.REACT_APP_API_URL,
  };

  const clientAxios = axios.create({
    ...options,
  });

  const handlerRequestAxios = function (config: InternalAxiosRequestConfig) {
    config.headers["Content-Type"] = "application/json";
    withToken && (config.headers["Authorization"] = `Bearer ${getToken()}`);
    formData && (config.headers["Content-Type"] = "multipart/form-data");
    return config;
  };
  withToken && clientAxios.interceptors.request.use(handlerRequestAxios);

  const refreshToken = async () => {
    const rToken = getRefreshToken();
    const username = getUsername();
    if (!rToken || !username) throw new Error("No autenticado.");
    const payload = {
      refreshToken: rToken,
      username,
    };

    try {
      const response = await clientAxios.post("/auth/refresh-session", payload);
      const { accessToken, refreshToken } = response.data.data;
      setToken(accessToken);
      setRefreshToken(refreshToken);
    } catch (e: any) {
      setRefreshToken("");
      setToken("");
      window.location.replace("/login");
      throw new Error(e);
    }
  };

  withToken &&
    clientAxios.interceptors.response.use(
      (res) => res,
      async function (error: any) {
        if (error && !error?.response) throw error;
        const req = error?.config;
        if (error?.response?.status === 401 && !req._retry) {
          req._retry = true;
          try {
            await refreshToken();
            const newAccessToken = getToken();
            const newHeader = `Bearer ${newAccessToken}`;
            // actuazliacion de token sobre el header de la llamada que se esta haciendo
            clientAxios.defaults.headers["Authorization"] = newHeader;
            // y tambien a la original que dio el 401
            req.headers["Authorization"] = newHeader;
            return clientAxios(req);
          } catch (error: any) {
            console.error({ error });
            setRefreshToken("");
            setToken("");
            window.location.replace("/login");
            return Promise.reject(error);
          }
        }
        return Promise.reject(error);
      }
    );

  return clientAxios;
};

export { getInstanceAxios };
