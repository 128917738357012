import { useCallback, useContext, useEffect, useState } from 'react';
import interceptorAuth from '../../interceptorAuth';
import { Box, styled } from '@mui/system';
import InputSearch from 'components/Users/InputSearch';
import UsersTable from '../../components/Users/UsersTable';
import ButtonAddUser from '../../components/UI/ButtonNavLink+';
import { AlertContext, AlertContextProps } from 'context/AlertContext/AlertProvidert';
import { Dialog, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import useUsers, { UserType } from 'hooks/useUsers';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'components/UI/Tooltip';
import Button from 'components/UI/Button';

export type UsersScreenProps = {
    isValidToken: boolean;
};

const ImgStyled = styled('img')`
  width: 240px;
  margin-right: 10px;
`;
const BoxStyled = styled(Box)`
    width: 400px;
    height: 100px;
    padding: 1rem;
`;

const UsersScreen = ({ isValidToken }: UsersScreenProps) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const {
        users,
        handleGetUsers,
        autocompleteData,
        handleSearchUsers,
        handleDeleteUser,
        handleSelectSerachedUser,
        form: {
            handleSubmit,
            register,
        },
        userSelected,
        setUserSelected,
    } = useUsers();

    const handleClose = async (confirm?: boolean) => {
        setOpenModal(false);
        if (confirm && userSelected) {
            const { userId } = userSelected;
            const response = await handleDeleteUser({ id: userId });
            setAlertData(response);    
        }
        
    };

    const getUsers = useCallback( async () => {
            await handleGetUsers();
        }, [handleGetUsers]
    );

    useEffect(() => { getUsers(); }, [getUsers]);

    const navigate = useNavigate();

    const handlerDeleteUser = async (row: UserType) => {
        setUserSelected(row);
        setOpenModal(true);
    };
    const handleDetailUser = (row: UserType) => {
        navigate(`/dashboard/users/${row.userId}`, { state: { titlePage: 'Detalle de Usuario'}});
    };
    const handleEditUser = (row: UserType) => {
        navigate(`/dashboard/users/${row.userId}/edit`, { state: { titlePage: 'Edición de Usuario'}});
    };

    return (
        <>
            <Dialog open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>
                    Eliminar Usuario
                </DialogTitle>
                <Divider sx={{ margin: "0"}} />
                <BoxStyled>
                    <Typography variant="h6" textAlign="center" marginTop={1} marginBottom={2}>
                        <strong>Desea eliminar el usuario ? </strong>
                    </Typography>
                    
                    <Grid container display="flex">
                        <Grid item margin="0 auto">
                            <Tooltip title="Aceptar">
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={() => handleClose(true)}
                                >
                                    Eliminar
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item margin="0 auto">
                            <Tooltip title="Cancelar">
                                <Button variant='contained' color="error" onClick={() => handleClose()}>
                                    Cancelar
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </BoxStyled>
            </Dialog>
            
            <Grid container width="100%" alignItems="center" marginBottom="2rem" marginTop="6rem">
                <Grid item margin="0 auto 0 1.5rem" width="50%">
                    <InputSearch
                        handleSubmit={handleSubmit}
                        fnOnSubmit={handleSelectSerachedUser}
                        register={register}
                        handleInputChange={handleSearchUsers}
                        data={autocompleteData}
                    />
                </Grid>
                <Grid item margin="0 0 auto auto">
                    <ButtonAddUser
                        title="Nuevo Sistema"
                        toUrl="/dashboard/users/create"
                        state={{ titlePage: 'Alta de Usuarios' }}
                    > Nuevo Usuario </ButtonAddUser>
                </Grid>
            </Grid>
            {users?.length === 0 &&
                <Box textAlign='center'>
                    <ImgStyled src="/assets/images/archivos.png" alt="No se encontraron registros" />
                    <Typography textAlign='center' color='#78909C' fontSize='17px' fontWeight='600'>No se encontraron registros</Typography>
                </Box>}
            {Array.isArray(users) && users?.length > 0 &&
                <UsersTable
                    rows={users}
                    handleEditUser={handleEditUser}
                    handleDetailUser={handleDetailUser}
                    handleDeleteUser={handlerDeleteUser}
                />}
            
            {isValidToken ? null : (
                <div style={{ textAlign: 'center' }}>Token no válido. Redirigiendo al inicio de sesión...</div>
            )}
        </>
    );
};

export default interceptorAuth(UsersScreen);
