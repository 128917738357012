import { Autocomplete, TextField, Tooltip, Button } from "@mui/material";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { MappedUsersAutocomplete } from "utils/api";
import { Box } from "@mui/system";

export type InputSearchProps<T> = {
    handleInputChange: (ev: any) => void;
    data: T[];
    register: any;
    handleSubmit: any;
    fnOnSubmit: any;
}
export type SearchInputProp = { search: string };

const InputSearch = ({ handleInputChange, data, register, handleSubmit, fnOnSubmit }: InputSearchProps<any>) => {

    /*   <Autocomplete
          freeSolo
          id="search-users"
          options={users}
          sx={{ minWidth: '50%' }}
          renderInput={(params) => ()}
            />
        */
    return (
        <form method="post"
            onSubmit={handleSubmit(fnOnSubmit)}
            onChange={handleInputChange}>
            <Box display="flex" alignItems="center">
                <TextField
                    fullWidth
                    type="search"
                    label="Buscar"
                    variant="outlined"
                    /* onKeyDown={e => (e.key === 'Enter' && handleInputChange(e))} */
                    onChange={handleInputChange}
                    {...register("search", { minLength: 3 })}
                />
                <Tooltip title="buscar">
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '50rem',
                        }}
                        color="primary"
                        type="submit"
                        startIcon={<SearchOutlinedIcon sx={{ marginLeft: '.7rem' }} />} />
                </Tooltip>
            </Box>
        </form>
    )
};

export default InputSearch;