import { Grid } from "@mui/material";
import DetailClientForm from "components/Clients/DetailClientForm";
import { AlertContext, AlertContextProps } from "context/AlertContext/AlertProvidert";
import useClientForm from "hooks/useClientForm";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

const DetailClientScreen = () => {
    const params = useParams() as { id: string };
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const navigate = useNavigate();
    const {
        form: { register, setValue, formState: { isValid } },
        client,
        setClient,
        services: {
            handleGetClientById
        }
    } = useClientForm();

    const CLIENT_ID_DEFAULT = 'client-id-reference';
    const API_KEY_DEFAULT = 'api-reference-key';

    const cb = useCallback(
        async () => {
            const res = await handleGetClientById(params.id);
            if (res?.data) {
                const { data: c } = res;
                setValue("nombre", c?.nombre);
                setValue("dominio", c?.dominio);
                setValue("emailSoporte", c?.email);
                setValue("urlRedireccion", c?.urlRespuesta);
                setValue("identificador", c?.identificador || CLIENT_ID_DEFAULT );
                setValue("apiKey", c?.apiKey || API_KEY_DEFAULT );
                c?.observaciones && setValue("observaciones", c.observaciones);
                setClient(res.data);
            } else {
                setAlertData({
                    alertMessage: res.alertMessage,
                    alertType: res.alertType
                });
                navigate('/dashboard/clients-manager', { state: { titlePage: 'Gestión de Sistemas' } });
            }
        }, [handleGetClientById, setAlertData, navigate]
    );

    useMemo( async () => { await cb(); }, [params.id] );

    const handleOnClose = () => {
        navigate('/dashboard/clients-manager', { state: { titlePage: 'Gestión de Sistemas' } })
    };

    const handleCopy = (value: string | undefined) => {
        navigator.clipboard.writeText(value || '').then(() => {
            setAlertData({
                alertMessage: 'Copiado al portapapeles',
                alertType: 'success'
            });
        }).catch((err) => {
            console.error('Error al copiar al portapapeles: ', err);
        });
    }

    return (
        <Grid container width="100%" margin="35px auto">
            <DetailClientForm
                client={client}
                isValid={isValid}
                register={register}
                handleOnClose={handleOnClose}
                isLoaded={!client}
                mode="detail"
                handleOnEditClient={undefined}
                handleCopy={handleCopy}
            />
        </Grid>
    );
};

export default DetailClientScreen;