import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import TextField from "components/UI/TextField";
import { IUseFormResponse } from "utils/hooks/useUtilsValidateForm";
import { useState } from "react";
import InputErrorLabel from "components/UI/InputErrorLabel";

const Step3: React.FC<IUseFormResponse> = ({ errors, handleInputChange, validateField, values }) => {
    const [mustIncludeItems, setMustIncludeItems] = useState(['8 Carácteres', '1 Mayúscula', '1 Minúscula', '1 Número', '1 Carácter Especial']);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    return (
        <Grid container spacing={2} justifyContent={'center'}>
            <Typography variant='h6' width={'100%'} marginBottom={4} textAlign={'left'} fontWeight={900}>
                Nueva Contraseña
            </Typography>

            {/* input token de validacion */}
            <Grid item xs={10}>
                <TextField
                    label="Token"
                    variant="outlined"
                    name="token"
                    fullWidth
                    value={values?.token?.value || ''}
                    onChange={(e) => handleInputChange('token', e.target.value)}
                    onKeyUp={() => validateField('token')}
                    error={!!errors?.token?.error}
                    helperText={errors?.token?.error && <InputErrorLabel icon error>{errors?.token?.error}</InputErrorLabel>}
                    required
                />
                
            </Grid>
            {/* input nueva password  */}
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    type={showPassword ? 'text': 'password'}
                    label="Nueva contraseña"
                    variant="outlined"
                    value={values?.newPassword?.value || ''}
                    name="newPassword"
                    onChange={(e) => handleInputChange('newPassword', e.target.value)}
                    onKeyUp={() => { validateField('newPassword'); validateField('confirmPassword');}}
                    onBlur={() => validateField('newPassword')}
                    error={!!errors?.newPassword?.error}
                    helperText={errors?.newPassword?.error && <InputErrorLabel icon error>{errors?.newPassword?.error}</InputErrorLabel>}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                />
                
            </Grid>
            {/* input confirmar nueva password */}
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    type={showConfirmPassword ? 'text': 'password'}
                    label="Confirmar nueva contraseña"
                    variant="outlined"
                    name="confirmPassword"
                    value={values?.confirmPassword?.value || ''}
                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                    onKeyUp={() => validateField('confirmPassword')}
                    onBlur={() => validateField('confirmPassword')}
                    error={!!errors?.confirmPassword?.error}
                    helperText={errors?.confirmPassword?.error && <InputErrorLabel icon error>{errors?.confirmPassword?.error}</InputErrorLabel>}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                />
            </Grid>
            {/* incluye alguno de estos */}
            <Grid container alignItems="center" margin="0 0 0 1.5rem" justifyContent="space-evenly" width="100%">
                <Typography margin={2} textAlign="center" width="100%">
                    Incluye al menos:{' '}
                </Typography>
                {
                    mustIncludeItems && mustIncludeItems.map((item, index) => (
                        <Typography
                            key={((new Date().getMilliseconds()) + index)}
                            variant="h5"
                            fontWeight={800}
                            margin="auto"
                            textAlign="center"
                            whiteSpace="nowrap"
                            width={`calc(100% / ${mustIncludeItems.length - 2})`}
                        >{item}</Typography>
                    ))
                }
            </Grid>

        </Grid>
    );
};
export default Step3;