import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import UserProvider from './context/UserProvider/UserProvider';

const adobeLink = document.createElement('link');
adobeLink.href = 'https://fonts.cdnfonts.com/css/adobe-clean';
adobeLink.rel = 'stylesheet';
document.head.appendChild(adobeLink);

const robotoLink = document.createElement('link');
robotoLink.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap';
robotoLink.rel = 'stylesheet';
document.head.appendChild(robotoLink);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <UserProvider>
        <App />
      </UserProvider>
    </React.StrictMode>
  );
} else {
  console.error('No se encontró el elemento contenedor con id "root"');
}
