import { Client, ClientFormProps } from 'hooks/useClientForm';
import * as AxiosClient from './clientAxiosBase';
import utils from '../utils';

const { services: { manageAPIResponse, manageApiErrorResponse } } = utils;

/**
 * This TypeScript function retrieves a list of clients from an API using Axios and handles any errors
 * that may occur.
 * @returns The `getClients` function returns an object with the following structure:
 * ```
 * {
 *   status: number,
 *   message: string,
 *   data: Client[] | null
 * }
 * ```
 * The `status` and `message` properties are derived from the `manageAPIResponse` function based on the
 * response status or error status code. The `data` property contains an array of `Client` objects
 */
export const getClients = async () => {
	try {
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios(`/clients`);
		const res = {
			...manageAPIResponse(response?.status, "sistema", response?.data?.message, "get"),
			data: response?.data?.data?.clientes as Client[]
		};
		return res;
	} catch (error: any) {
		console.error({ getClients: error });
		return {
			...manageApiErrorResponse(error?.response?.statusCode || 500, "sistema", "get", error?.response?.data?.message || "Error el obtener los clientes."),
			data: null
		}
	}
};
/**
 * The function `postCreateClients` sends a POST request to create a new client with the provided data
 * and handles the API response.
 * @param {ClientFormProps} data - The `data` parameter in the `postCreateClients` function is of type
 * `ClientFormProps`. It contains information about a client such as their name, domain, redirect URL,
 * support email, and optional observations. This data is used to create a new client by sending a POST
 * request to the
 * @returns The function `postCreateClients` is returning the result of calling `manageAPIResponse`
 * with the status from the API response or a default status code of 500 if an error occurs.
 */
export const postCreateClients = async (data: ClientFormProps) => {
	try {
		const fd = new FormData();

		fd.append('nombre', data?.nombre);
		fd.append('dominio', btoa(data?.dominio));
		fd.append('urlRespuesta', btoa(data?.urlRedireccion));
		fd.append('email', data?.emailSoporte);
 
		data?.observaciones && fd.append('observaciones', data.observaciones);

		const axios = AxiosClient.getInstanceAxios(true, true);
		const response = await axios.post(`/clients`, fd);
		return manageAPIResponse(response?.status, "sistema", undefined , "create");
	} catch (error: any) {
		console.error({ createClient: error });
		return manageApiErrorResponse(error?.response.statusCode || 500, "sistema", "create", error?.response?.data?.message);
	};
};
/**
 * The function `putUpdateClient` sends a PUT request to update a client's information using FormData
 * and Axios in TypeScript.
 * @param {Client} data - The `data` parameter in the `putUpdateClient` function represents an object
 * of type `Client`. It contains the following properties:
 * @returns The function `putUpdateClient` is returning the result of the `manageAPIResponse` function
 * based on the status of the API response. The `manageAPIResponse` function is handling the response
 * status and returning it.
 */
export const putUpdateClient = async (data: Client) => {
	try {
		const fd = new FormData();
		fd.append('nombre', data?.nombre);
		fd.append('dominio', btoa(data?.dominio));
		fd.append('urlRespuesta', btoa(data?.urlRedireccion));
		fd.append('email', data?.emailSoporte);

		data?.observaciones && fd.append('observaciones', data?.observaciones);
		const payload = {
			nombre: data?.nombre,
			urlRespuesta: btoa(data?.urlRedireccion),
			dominio: btoa(data?.dominio),
			email: data?.emailSoporte,
			observaciones: data?.observaciones,
			id: data?.id
		};
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios.put(`/clients/${data?.id}`, payload);
		return manageAPIResponse(response?.status, "sistema", undefined, "update");

	} catch (error: any) {
		console.error({ updateClient: error });
		return manageApiErrorResponse(error?.response?.statusCode || 500, "sistema", "update", error?.response?.data?.message);
	}	
};
/**
 * This TypeScript function deletes a client by making a DELETE request to the specified endpoint and
 * handles the API response accordingly.
 * @param {number | string} id - The `id` parameter in the `deleteClient` function can be either a
 * number or a string. It is used to identify the client that needs to be deleted from the server.
 * @returns The `deleteClient` function returns the result of calling the `manageAPIResponse` function
 * with the status from the response if successful, or the status code from the error response if an
 * error occurs.
 */
export const deleteClient = async (id: number | string) => {
	try {
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios.delete(`/clients/${id}`);
		return manageAPIResponse(response?.status, "sistema", undefined, "delete");
	} catch (error: any) {
		console.error({ delteClient: error });
		return manageApiErrorResponse(error?.response?.statusCode || 500, "sistema", "delete", error?.response?.data?.message);
	}
}
/**
 * The function `getClientById` retrieves client data by ID using Axios and handles errors
 * appropriately.
 * @param {string | number} id - The `id` parameter in the `getClientById` function is a string or
 * number that represents the unique identifier of a client. This identifier is used to fetch the
 * client data from the API endpoint `/clients/{id}`.
 * @returns The `getClientById` function is returning the data of the client with the specified `id` if
 * the request is successful. If there is an error during the request, it will log the error and return
 * the status code of the error response or a default status code of 500.
 */
export const getClientById = async (id: string | number) => {
	try {
		const axios = AxiosClient.getInstanceAxios();
		const response = await axios(`/clients/${id}`);
		const res = {
			...manageAPIResponse(response?.status, "sistema", undefined, "get"),
			data: response?.data?.data
		};
		return res;
	} catch (error: any) {
		console.error({ getClientById: error });
		return {
			...manageApiErrorResponse(error?.response?.statusCode, "sistema", "get", error?.response?.data?.message),
			data: null
		};
	}
};
