import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as AxiosClient from '../../../services/clientAxiosBase';
import { useUser } from '../../../context/UserProvider/UserProvider';

const axios = AxiosClient?.getInstanceAxios(false, false);

const useLogin = () => {
	const { setUsername } = useUser();
	const navigate = useNavigate();
	const [error, setError] = useState<{ message: string; severity: 'error' | 'warning' } | null>(null);

	const handleLogin = async (username: string, password: string) => {
		setError(null);

		if (!username || !password) {
			setError({ message: 'Por favor, ingresa un usuario y una contraseña.', severity: 'error' });
			return;
		}

		try {
			const base64EncodedPassword = btoa(password);

			const response = await axios.post(
				`/auth/login`,
				`username=${username}&password=${base64EncodedPassword}`,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				}
			);
			
			const { jwtToken } = response?.data?.data?.token?.accessToken;
			const { token } = response?.data?.data?.token?.refreshToken;
			const { payload } = response?.data?.data?.token?.idToken;
			
			jwtToken && localStorage.setItem('accessToken', jwtToken);
			token && localStorage.setItem('refreshToken', token);
			payload && localStorage.setItem('username', payload['cognito:username']);
      
			setUsername(response?.data?.data?.token?.idToken?.payload['cognito:username']);
			navigate('/dashboard/users', { state: { titlePage: 'Gestión de Usuarios'}});
		} catch (error: any) {
			const defaultMessageError = '¡Ups! ¡Estamos teniendo problemas en nuestro sistema, intentarlo más tarde!';
			if (error) {
				if (error?.response) {

					if (error?.response?.status === 400) {
						const { data } = error?.response;
						if (data.code === 'UserNotFoundException' && data.code === 'NotAuthorizedException') {
							setError({ message: 'Usuario y/o contraseña incorrecto', severity: 'error' });
						} else {
							setError({ message: defaultMessageError, severity: 'warning' });
						}
					}
					if (error?.response.status === 404) {
						// const { data } = error.response;
						setError({ message: defaultMessageError, severity: 'warning' });
					}
				} else {
					setError({ message: defaultMessageError, severity: 'warning' });
				}
			} else {
				setError({ message: defaultMessageError, severity: 'warning' });
			}
		}
	};
	return { handleLogin, error, setError };
};

export default useLogin;
