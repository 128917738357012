import { Grid } from "@mui/material";
import DetailClientForm from "components/Clients/DetailClientForm";
import { AlertContext, AlertContextProps } from "context/AlertContext/AlertProvidert";
import useClientForm, { Client } from "hooks/useClientForm";
import { AlertSeverityType } from "hooks/useDisplayAlert";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditClientScreen = () => {
    const { setAlertData } = useContext(AlertContext) as AlertContextProps;
    const params = useParams() as { id: string };
    const navigate = useNavigate();
    const {
        form: { register, handleSubmit, setValue, formState: { isValid } },
        client,
        setClient,
        services: {
            handleGetClientById,
            handleUpdateClient
        }
    } = useClientForm();

    const CLIENT_ID_DEFAULT = 'client-id-reference';
    const API_KEY_DEFAULT = 'api-reference-key';

    const cb = useCallback(
        async () => {
            const res = await handleGetClientById(params.id);
            if (res?.data) {
                const { data: c } = res;
                setValue("nombre", c?.nombre)
                setValue("dominio", c?.dominio)
                setValue("emailSoporte", c?.email)
                setValue("urlRedireccion", c?.urlRespuesta);
                setValue("identificador", c?.identificador || CLIENT_ID_DEFAULT );
                setValue("apiKey", c?.apiKey || API_KEY_DEFAULT );
                c?.observaciones && setValue("observaciones", c.observaciones);
                setClient(res.data);
            } else {
                setAlertData({
                    alertMessage: res?.alertMessage,
                    alertType: res?.alertType
                });
                navigate('/dashboard/clients-manager', { state: { titlePage: 'Gestión de Sistemas' } });
            }
        }, [handleGetClientById, setAlertData, navigate]
    );

    useMemo( async () => { await cb(); }, [params.id] );

    const handleOnEditClient = async (data: Client) => {
        const response = await handleUpdateClient({ ...data, id: params.id });
        const { alertMessage, alertType } = response;

        if (alertType === "success") {
            setAlertData({ alertMessage, alertType: alertType as AlertSeverityType });
            navigate('/dashboard/clients-manager', { state: { titlePage: 'Gestión de Sistemas' } });
        } else {

            if (Array.isArray(response?.alertMessage)) {
                const messagges = response?.alertMessage?.flat();
                setAlertData({ alertMessage: messagges, alertType: response?.alertType || 'error' });
            } else {
                setAlertData({
                    alertMessage: response?.alertMessage,
                    alertType: response?.alertType
                });
            }
        };
    };

    const handleOnClose = () => {
        navigate('/dashboard/clients-manager', { state: { titlePage: 'Gestión de Sistemas' } });
    };

    const handleCopy = (value: string | undefined) => {
        navigator.clipboard.writeText(value || '').then(() => {
            setAlertData({
                alertMessage: 'Copiado al portapapeles',
                alertType: 'success'
            });
        }).catch((err) => {
            console.error('Error al copiar al portapapeles: ', err);
        });
    }

    return (
        <Grid container width="100%" margin="35px auto">
            <DetailClientForm
                isValid={isValid}
                client={client}
                register={register}
                handleOnClose={handleOnClose}
                isLoaded={!client}
                mode="edit"
                handleOnEditClient={handleSubmit(handleOnEditClient)}
                handleCopy={handleCopy}
            />
        </Grid>
    );
};

export default EditClientScreen;