import React from "react";
import { UseFormRegister } from "react-hook-form";
import { Button, Divider, Grid, IconButton, InputAdornment, styled, TextField, Typography } from "@mui/material";
import TextareaAutosize, { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import Tooltip from "components/UI/Tooltip";
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import { Client } from "hooks/useClientForm";

export type DetailClientFormProps = {
    register: UseFormRegister<Client>;
    handleOnClose: () => void;
    isLoaded: boolean;
    mode: "detail" | "edit";
    handleOnEditClient?: () => void;
    client: Client | null;
    isValid: boolean;
    handleCopy: (value: string | undefined) => void;
};

const WrapperForm = styled(Grid)`
    padding: calc(3 * .6rem);
    border: 1px solid #DADCE0;
    border-radius: 5px;
    margin: 53px auto;
`;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>((props, ref) => {
    return <TextareaAutosize ref={ref} minRows={3} {...props} />;
});

const DetailClientForm = ({ register, handleOnClose, mode, handleOnEditClient, client, isValid, handleCopy }: DetailClientFormProps) => {
    const isDetailMode = (mode: "detail" | "edit") => mode === "detail";
    return (
        <React.Fragment>
            { register && <WrapperForm>
                <Typography variant="h1" gutterBottom>
                    Datos del Sistema
                </Typography>

                <Divider />

                <form>
                    <Grid container spacing={4}>
                        {/* NOMBRE */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                id="nombre"
                                label="Nombre"
                                variant="outlined"
                                type="text"
                                fullWidth
                                {...register("nombre", { disabled: isDetailMode(mode) })}
                                InputLabelProps={{ shrink: Boolean(client?.nombre) }}
                            />
                        </Grid>
                        {/* DOMINIO  */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                id="dominio"
                                label="Dominio"
                                variant="outlined"
                                type="text"
                                fullWidth
                                {...register("dominio", { disabled: isDetailMode(mode) })}
                                InputLabelProps={{ shrink: Boolean(client?.dominio) }}
                            />
                        </Grid>
                        {/* URL REDIRECCION */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                id="urlRedireccion"
                                label="Url Redirección"
                                variant="outlined"
                                type="text"
                                fullWidth
                                {...register("urlRedireccion", { disabled: isDetailMode(mode) })}
                                InputLabelProps={{ shrink: Boolean(client?.urlRespuesta) }}
                            />
                        </Grid>
                        {/* CORREO ELECTRONICO */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                id="emailSoporte"
                                label="Mail Electrónico"
                                variant="outlined"
                                type="email"
                                fullWidth
                                {...register("emailSoporte", { disabled: isDetailMode(mode) })}
                                InputLabelProps={{ shrink: Boolean(client?.email) }}
                            />
                        </Grid>
                        {/* OBSERVACIONES */}
                        <Grid item sm={12} md={12} lg={12} xs={12}>
                            <TextField
                                id="observaciones"
                                label="Observaciones"
                                multiline
                                fullWidth
                                placeholder={`${client && client.observaciones ? client.observaciones : "Observaciones"}`}
                                InputProps={{
                                    inputComponent: Textarea,
                                }}
                                {...register("observaciones", { disabled: isDetailMode(mode)})}
                                InputLabelProps={{ shrink: client?.observaciones ? Boolean(client?.observaciones) : true }}
                            />
                        </Grid>
                        {/* API_KEY */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                id="apiKey"
                                label="API_KEY"
                                variant="outlined"
                                type="text"
                                fullWidth
                                {...register("apiKey", { disabled: true })}
                                InputLabelProps={{ shrink: Boolean(client?.apiKey) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="copiar en portapapeles">
                                                <IconButton onClick={() => handleCopy(client?.apiKey)}>
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        {/* CLIENT_ID */}
                        <Grid item sm={12} md={6} lg={6} xs={12}>
                            <TextField
                                id="identificador"
                                label="CLIENT_ID"
                                variant="outlined"
                                type="text"
                                fullWidth
                                {...register("identificador", { disabled: true })}
                                InputLabelProps={{ shrink: Boolean(client?.identificador) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="copiar en portapapeles">
                                                <IconButton onClick={() => handleCopy(client?.identificador)}>
                                                    <CopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        
                        {/* Container buttons 'enviar' y 'cancelar' */}
                        <Grid item sm={12} md={12} lg={12} xs={12} textAlign="right">
                            <Tooltip title={`Cancelar ${isDetailMode(mode) ? "vista detallada" : "edición"} del sistema`}>
                                <Button variant="outlined" color="secondary" onClick={handleOnClose}>
                                    Cancelar
                                </Button>
                            </Tooltip>
                            {!isDetailMode(mode) &&
                                <Tooltip title="Aceptar edición del sistema">
                                    <Button
                                        variant="contained"
                                        onClick={handleOnEditClient}
                                        disabled={!isValid}
                                    >
                                        Editar Sistema
                                    </Button>
                                </Tooltip>}
                        </Grid>
                    </Grid>
                </form>
            </WrapperForm>}
        </React.Fragment>
    );
};

export default DetailClientForm;
